import { Typography } from "@material-ui/core"
import * as React from "react"
import { NumberWrap } from "./assets"

export const Bullet: React.FC<{
  number: string
  style?: React.CSSProperties
}> = (props) => {
  return (
    <div
      style={{
        position: "relative",
        display: "grid",
        placeItems: "center",
        height: "26px",
        width: "26px",
        ...props.style,
      }}
    >
      <img
        alt="bullet"
        src={NumberWrap}
        style={{ height: "26px", position: "absolute", top: 0, left: 0 }}
      />
      <Typography variant="body2">{props.number}</Typography>
    </div>
  )
}
