import * as React from "react";
/**
 * Usage example:
 * ```ts
 * const screenType = useScreenType()
 * // render based on screenType
 * ```
 *
 * This is a custom React Hook.
 * See https://reactjs.org/docs/hooks-custom.html
 */
export const useScreenType = () => {
    const [screenType, setScreenType] = React.useState(currentScreenType());
    React.useEffect(() => {
        const listener = () => {
            const width = window.innerWidth;
            setScreenType(convertWidthToScreenType(width));
        };
        window.addEventListener("resize", listener);
        return () => {
            window.removeEventListener("resize", listener);
        };
    }, []);
    return screenType;
};
export const currentScreenType = () => {
    return convertWidthToScreenType(window.innerWidth);
};
export const convertWidthToScreenType = (width) => {
    if (width >= 1920) {
        return "xl-desktop";
    }
    else if (width >= 1280) {
        return "lg-desktop";
    }
    else if (width >= 960) {
        return "md-desktop";
    }
    else if (width >= 600) {
        return "sm-tablet";
    }
    else {
        return "xs-phone";
    }
};
export const watchScreenSize = ({ onChange, }) => {
    window.addEventListener("resize", () => {
        const width = window.innerWidth;
        onChange({ width, screenType: convertWidthToScreenType(width) });
    });
};
