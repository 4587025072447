import Pagination from "@material-ui/lab/Pagination/Pagination"
import React from "react"

export const Paginator: React.FC<{
  pagingOptions: { currentPage: number; itemsPerPage: number }
  totalCount: number
  onChange: (currentPage: number) => void
}> = (props) => {
  const count = Math.ceil(props.totalCount / props.pagingOptions.itemsPerPage)
  if (count < 2) {
    return null
  }
  return (
    <Pagination
      style={{ justifySelf: "center", marginBottom: 8 }}
      count={count}
      onChange={(_, page) => props.onChange(page - 1)}
    />
  )
}
