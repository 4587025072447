import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core"
import { ExpandMore } from "@material-ui/icons"
import React from "react"

export const Section: React.FC<{
  title: string | JSX.Element
  subtitle?: string | JSX.Element
  showItemCount: boolean
  action?: {
    icon: () => JSX.Element
    onClick: () => void
    tooltip: string
  }
  disableTable?: boolean
  aboveTable?: JSX.Element
  table: {
    columns: string[]
    rows: {
      values: JSX.Element[]
    }[]
  }
  dontExpandByDefault?: boolean
}> = (props) => {
  if (
    props.table.rows[0] &&
    props.table.columns.length !== props.table.rows[0]?.values.length
  ) {
    throw new Error(
      `table.columns.length does not equal to table.rows[0]?.values.length`,
    )
  }
  return (
    <Accordion
      style={{ backgroundColor: "#F8F8F8" }}
      defaultExpanded={!props.dontExpandByDefault}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "auto 1fr auto",
            width: "100%",
            alignItems: "center",
            justifyContent: "start",
            gap: "8px",
          }}
        >
          {props.action && (
            <Tooltip title={props.action.tooltip}>
              <IconButton
                style={{ padding: 0, marginRight: "18px", alignSelf: "start" }}
                onClick={(event) => {
                  props.action?.onClick()
                  event.stopPropagation()
                }}
              >
                <props.action.icon />
              </IconButton>
            </Tooltip>
          )}
          <div style={{ display: "grid", alignItems: "center", gridColumn: 2 }}>
            {typeof props.title === "string" ? (
              <Typography style={{ fontWeight: "bold" }}>
                {props.title}
              </Typography>
            ) : (
              props.title
            )}
            {props.subtitle &&
              (typeof props.subtitle === "string" ? (
                <Typography
                  style={{ wordBreak: "break-all" }}
                  variant="caption"
                >
                  {props.subtitle}
                </Typography>
              ) : (
                props.subtitle
              ))}
          </div>
          {props.showItemCount && (
            <Typography style={{ justifySelf: "end" }}>
              {"( "}
              {props.table.rows.length}
              {" )"}
            </Typography>
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails style={{ display: "grid" }}>
        {props.aboveTable}
        <div style={{ overflowX: "auto", display: "grid" }}>
          <Table
            size="small"
            style={{
              whiteSpace: "nowrap",
              opacity: props.disableTable ? 0.6 : undefined,
            }}
          >
            <TableHead>
              <TableRow>
                {props.table.columns.map((column, index) => (
                  <TableCell key={index} style={{ fontSize: "12px" }}>
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.table.rows.map((row, index) => (
                <TableRow key={index}>
                  {row.values.map((value, index) => (
                    <TableCell key={index}>{value}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </AccordionDetails>
    </Accordion>
  )
}
