import { Typography } from "@material-ui/core"
import * as React from "react"

export const Tag: React.FC<{
  backgroundColor: string
  color?: string
  label: string
  icon?: string
  style?: React.CSSProperties
}> = (props) => (
  <div
    style={{
      ...props.style,
      background: props.backgroundColor,
      borderRadius: "4px",
      display: "grid",
      alignItems: "center",
      padding: "2px 8px",
      gap: "2px",
      gridAutoFlow: "column",
    }}
  >
    {props.icon && (
      <img
        alt={props.label}
        src={props.icon}
        style={{ height: "16px", marginLeft: "-2px" }}
      />
    )}
    <Typography variant="caption" style={{ color: props.color ?? "white" }}>
      {props.label}
    </Typography>
  </div>
)
