import { Typography } from "@material-ui/core"
import * as React from "react"
import { Tag } from "./tag"

export const SectionTitle: React.FC<{
  title: string
  description: string
  tag?: {
    label: string
    color: string
  }
}> = (props) => {
  return (
    <div style={{ display: "grid" }}>
      <div
        style={{
          display: "grid",
          gridAutoFlow: "column",
          justifyContent: "start",
          gridGap: "8px",
          alignItems: "center",
        }}
      >
        <Typography style={{ fontWeight: "bold" }} variant="h6">
          {props.title}
        </Typography>
        {props.tag && (
          <Tag backgroundColor={props.tag.color} label={props.tag.label} />
        )}
      </div>
      <Typography variant="caption" style={{ marginBottom: "18px" }}>
        {props.description}
      </Typography>
    </div>
  )
}
