import Dialog from "@material-ui/core/Dialog"
import IconButton from "@material-ui/core/IconButton"
import Tooltip from "@material-ui/core/Tooltip"
import Typography from "@material-ui/core/Typography"
import { CloudDownload } from "@material-ui/icons"
import React from "react"
//@ts-ignore
import { PDFReader } from "reactjs-pdf-reader"
import { graphqlSdk } from "../../../graphql/sdk"
import { PrimaryButton } from "../../common/custom-button"
import { LoadingCircle } from "../../common/loading-circle"
import { SlideUp } from "../../common/slideup"
import { colors } from "../../common/style"
import { formatAgency, formatChildProject } from "../common/util"
import { ReceivedSomethingDialog } from "../received-something-dialog"
import {
  ProjectAgencyGroup,
  ProjectAgencyGroupTncStatus,
  Tnc,
} from "./project-agency-group-section"

export const TncDialog: React.FC<{
  open: boolean
  data:
    | {
        token: string
        projectAgencyGroup: ProjectAgencyGroup
        tnc: Tnc
        status: ProjectAgencyGroupTncStatus
      }
    | undefined
  onClose: () => void
}> = (props) => {
  const [state, setState] = React.useState({
    loading: true,
    dialog: undefined as "after_agreed" | undefined,
  })

  const tnc = props.data?.tnc
  const projectAgencyGroup = props.data?.projectAgencyGroup
  const parentAgency = projectAgencyGroup?.agencyGroup.parentAgency.agency
  const childProject = projectAgencyGroup?.agencyKind.childProject
  const agree = () => {
    if (!props.data) {
      return alert("[ERROR]: props.data is undefined")
    }
    setState((state) => ({ ...state, loading: true }))
    const tncVersionId = props.data.tnc.latestVersion.id
    const projectAgencyGroupId = props.data.projectAgencyGroup.id
    analytics.track("Agree TNC", {
      tncVersionId,
      projectAgencyGroupId,
    })
    graphqlSdk()
      .PaymentPortal_TncDialog_AgreeTnc({
        token: props.data.token,
        tncVersionId,
        projectAgencyGroupId,
      })
      .then(() => {
        setState((state) => ({
          ...state,
          loading: false,
          dialog: "after_agreed",
        }))
      })
      .catch((error) => {
        setState((state) => ({ ...state, loading: false }))
        alert(error)
      })
  }

  const title = `${formatChildProject(childProject)} @ ${formatAgency(
    parentAgency,
  )}`

  return (
    <Dialog open={props.open} TransitionComponent={SlideUp} fullScreen>
      <div
        style={{
          display: "grid",
          width: `100vw`,
          height: `100vh`,
          maxWidth: 1040,
          gridTemplateRows: "auto 1fr auto",
          alignSelf: "center",
        }}
      >
        <LoadingCircle open={state.loading} />
        <Typography style={{ textAlign: "center", margin: 24 }} variant="h6">
          {title}
        </Typography>

        {tnc?.latestVersion.file.signedURL && (
          <div
            style={{
              margin: `0 24px`,
              border: `2px solid ${colors.turquoise2}`,
              borderRadius: 8,
              padding: 2,
              position: "relative",
              display: "grid",
              overflow: "hidden",
            }}
          >
            <Tooltip title="Download">
              <IconButton
                style={{ position: "absolute", right: 24, top: 16 }}
                onClick={() => {
                  window.open(tnc?.latestVersion.file.signedURL)
                }}
              >
                <CloudDownload />
              </IconButton>
            </Tooltip>

            <div style={{ overflow: "auto", display: "grid" }}>
              <PDFReader
                key={tnc?.id}
                showAllPages
                onDocumentComplete={() =>
                  setState((state) => ({ ...state, loading: false }))
                }
                url={tnc.latestVersion.file.signedURL}
              />
            </div>
          </div>
        )}

        <div
          style={{
            display: "grid",
            gap: 8,
            justifyContent: "center",
            margin: "16px 0",
          }}
        >
          {(() => {
            switch (props.data?.status.__typename) {
              case "ProjectAgencyGroupCommissionTncStatusPendingAgreement":
              case "ProjectAgencyGroupCommissionTncStatusPendingReagreement": {
                return (
                  <PrimaryButton
                    variant="primary"
                    word="Agree"
                    onClick={agree}
                  />
                )
              }
            }
          })()}
          <PrimaryButton
            variant="secondary"
            word="Close"
            onClick={props.onClose}
          />
        </div>
      </div>
      <ReceivedSomethingDialog
        open={state.dialog === "after_agreed"}
        onClose={() => {
          setState((state) => ({ ...state, dialog: undefined }))
          props.onClose()
        }}
        title={`You have agreed to this T&C.`}
        subtitle="You can now access this project in the Didian App and sell without any interuption."
      />
    </Dialog>
  )
}
