import { Paper, Typography } from "@material-ui/core"
import * as React from "react"
import { TabProps } from ".."
import {
  InvoiceRequestQueryFragment,
  PagingOptions,
} from "../../../graphql/generated/root.graphql"
import { graphqlSdk } from "../../../graphql/sdk"
import { toUpload } from "../../../graphql/to-upload"
import { getFiles } from "../../../utils/get-files"
import { BinIcon, PdfIcon } from "../../common/assets"
import { PrimaryButton } from "../../common/custom-button"
import { Paginator } from "../../common/paginator"
import { colors } from "../../common/style"
import { TabPage } from "../common/tab-page"
import {
  InvoiceRequestGroupView,
  InvoiceRequestsGroup,
} from "../invoice-request-group"
import { ReceivedInvoiceDialog } from "../received-invoice-dialog"

export type InvoiceRequest = InvoiceRequestQueryFragment
export const InvoiceRequestTab: React.FC<{
  tabProps: TabProps
}> = ({
  tabProps: {
    isSmallScreen,
    token,
    setLoading,
    handleError,
    showInvoiceRequest,
    focusUnsignedAppointmentLetter,
    changeTab,
  },
}) => {
  const [state, setState] = React.useState({
    showSnackbar: false,
    selectedInvoiceRequestIds: [] as string[],
    uploadedInvoice: undefined as File | undefined,
    clickedOnContinueButton: 0,
    invoiceRequests: [] as InvoiceRequest[],
    dialog: undefined as "preupload_invoice" | "received_invoice" | undefined,

    // We use 20 items per page here so to minimise the chance that
    // the PIC wanted to upload an invoice for two invoice requests, but the
    // two is located at different pages.
    pagingOptions: { currentPage: 0, itemsPerPage: 20 } as PagingOptions,
    totalCount: 0,
  })

  const fetchData = React.useCallback(
    (prepromise: Promise<void>) => {
      setLoading(true)
      prepromise
        .then(() => {
          analytics.track("Display invoice requests", {})
          return graphqlSdk()
            .PaymentPortal_InvoiceRequestTab({
              token,
              pagingOptions: state.pagingOptions,
            })
            .then(
              ({
                paymentPortal: { invoiceRequests: { findMany: result } },
              }) => {
                setLoading(false)
                setState((state) => ({
                  ...state,
                  invoiceRequests: result.paginate,
                  totalCount: result.totalCount,
                  selectedInvoiceRequestIds: [],
                  uploadedInvoice: undefined,
                  clickedOnContinueButton: 0,
                }))
              },
            )
        })
        .catch(handleError)
    },
    [handleError, setLoading, token, state.pagingOptions],
  )

  React.useEffect(() => {
    fetchData(Promise.resolve())
  }, [fetchData])

  const showCheckbox = Boolean(state.showSnackbar)
  const showCheckboxCue =
    showCheckbox && state.selectedInvoiceRequestIds.length === 0

  const onSelectInvoiceRequest = (invoiceRequestId: string) => {
    setState({
      ...state,
      clickedOnContinueButton: 0,
      selectedInvoiceRequestIds: state.selectedInvoiceRequestIds.includes(
        invoiceRequestId,
      )
        ? state.selectedInvoiceRequestIds.filter(
            (id) => id !== invoiceRequestId,
          )
        : [...state.selectedInvoiceRequestIds, invoiceRequestId],
    })
  }

  const groupInvoiceRequests = (
    invoiceRequests: InvoiceRequest[],
  ): InvoiceRequestsGroup[] => {
    const keyMatch = (
      group: InvoiceRequestsGroup,
      invoiceRequest: InvoiceRequest,
    ): boolean => {
      return (
        group.agency?.id === invoiceRequest.agency?.id &&
        group.parentProject?.id ===
          invoiceRequest.transaction?.project?.parent?.id &&
        group.childProject?.id === invoiceRequest.transaction?.project?.id
      )
    }
    return invoiceRequests.reduce((groups, invoiceRequest) => {
      const groupExists = groups.find((group) =>
        keyMatch(group, invoiceRequest),
      )
      return groupExists
        ? groups.map((group) =>
            keyMatch(group, invoiceRequest)
              ? {
                  ...group,
                  invoiceRequests: [...group.invoiceRequests, invoiceRequest],
                }
              : group,
          )
        : [
            ...groups,
            {
              parentProject: invoiceRequest.transaction?.project?.parent,
              childProject: invoiceRequest.transaction?.project,
              agency: invoiceRequest.agency,
              invoiceRequests: [invoiceRequest],
              unsignedAppointmentLetterId: undefined,
            },
          ]
    }, [] as InvoiceRequestsGroup[])
  }
  return (
    <TabPage
      title="Upload Invoice"
      description={
        state.invoiceRequests.length > 0
          ? "The following units are ready for invoicing."
          : "You don't have any sales ready for invoicing at this time. You can view your sales in the Didian App or feel free to contact your ARM if you have any questions about your sales."
      }
    >
      <div style={{ display: "grid", gridGap: "36px", width: "100%" }}>
        <InvoiceRequestGroupView
          showCheckbox={showCheckbox}
          showCheckboxCue={showCheckboxCue}
          isSmallScreen={isSmallScreen}
          selectedInvoiceRequestIds={state.selectedInvoiceRequestIds}
          onSelectInvoiceRequest={onSelectInvoiceRequest}
          invoiceRequestGroups={groupInvoiceRequests(state.invoiceRequests)}
          showInvoiceRequest={showInvoiceRequest}
          focusUnsignedAppointmentLetter={focusUnsignedAppointmentLetter}
        />
        {!state.uploadedInvoice && (
          <Paginator
            pagingOptions={state.pagingOptions}
            totalCount={state.totalCount}
            onChange={(currentPage) =>
              setState((state) => ({
                ...state,
                pagingOptions: { ...state.pagingOptions, currentPage },
              }))
            }
          />
        )}
        <div />
      </div>
      <div style={{ height: "100px" }} />
      <Paper
        style={{
          position: "absolute",
          bottom: state.showSnackbar ? "88px" : "-100px",
          width: "300px",
          left: "50%",
          marginLeft: "-150px",
          transition: "0.4s bottom",
          zIndex: 1,
          display: "grid",
          placeItems: "center",
          overflow: "auto",
        }}
        className={
          state.clickedOnContinueButton > 0
            ? "wiggle" + (state.clickedOnContinueButton % 2)
            : undefined
        }
      >
        <div
          style={{
            transition: "background-color 0.23s",
            backgroundColor: state.clickedOnContinueButton
              ? colors.orange1
              : colors.turquoise2,
            padding: "8px",
            justifySelf: "stretch",
            textAlign: "center",
          }}
        >
          {state.selectedInvoiceRequestIds.length === 0 ? (
            <Typography
              variant="caption"
              style={{ color: "white", fontWeight: "bold" }}
            >
              Tick units related to this invoice.
            </Typography>
          ) : (
            <Typography
              variant="caption"
              style={{ color: "white", fontWeight: "bold" }}
            >
              {state.selectedInvoiceRequestIds.length} unit
              {state.selectedInvoiceRequestIds.length > 1 ? "s" : ""} selected
            </Typography>
          )}
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "auto 1fr auto",
            padding: "8px 12px",
            placeItems: "center",
            gap: "8px",
            justifySelf: "stretch",
          }}
        >
          <img alt="PDF" src={PdfIcon} style={{ height: "24px" }} />
          <div style={{ overflowY: "auto", display: "grid", width: "100%" }}>
            <Typography style={{ wordBreak: "break-all" }} variant="caption">
              {state.uploadedInvoice?.name}
            </Typography>
          </div>
          <img
            alt="Delete"
            src={BinIcon}
            style={{ height: "24px", cursor: "pointer" }}
            onClick={() =>
              setState((state) => ({
                ...state,
                showSnackbar: false,
                selectedInvoiceRequestIds: [],
                clickedOnContinueButton: 0,
              }))
            }
          />
        </div>
      </Paper>
      <div
        style={{
          backgroundColor: "white",
          boxShadow: `
          4px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)
        `,
          height: "78px",
          transition: "height 0.3s",
          width: "100%",
          zIndex: 2,
          position: "absolute",
          bottom: 0,
          left: 0,
          display: "grid",
          alignItems: "center",
          justifyItems: "center",
          gap: "4px",
        }}
      >
        <PrimaryButton
          style={{ margin: "12px 0" }}
          variant="primary"
          disabled={Boolean(
            (state.showSnackbar &&
              state.selectedInvoiceRequestIds.length === 0) ||
              state.invoiceRequests.length === 0,
          )}
          onClick={() => {
            if (
              state.showSnackbar &&
              state.uploadedInvoice &&
              state.selectedInvoiceRequestIds.length > 0
            ) {
              const invoiceRequestIds = state.selectedInvoiceRequestIds
              analytics.track("Upload invoice", { invoiceRequestIds })
              fetchData(
                graphqlSdk()
                  .PaymentPortal_InvoiceRequestTab_MainUploadInvoice({
                    token,
                    invoiceRequestIds,
                    files: [state.uploadedInvoice].map(toUpload),
                  })
                  .then(() => {
                    setState((state) => ({
                      ...state,
                      dialog: "received_invoice",
                      showSnackbar: false,
                      selectedInvoiceRequestIds: [],
                    }))
                  }),
              )
            } else if (
              state.showSnackbar &&
              state.selectedInvoiceRequestIds.length === 0
            ) {
              setState((state) => ({
                ...state,
                clickedOnContinueButton: state.clickedOnContinueButton + 1,
              }))
            } else {
              getFiles({ accept: "application/pdf" }).then(([file]) => {
                setState((state) => ({
                  ...state,
                  uploadedInvoice: file,
                  selectedInvoiceRequestIds: [],
                  showSnackbar: true,
                }))
              })
            }
          }}
          word={!state.showSnackbar ? "Upload Invoice PDF" : "Continue"}
        />
      </div>
      <ReceivedInvoiceDialog
        open={state.dialog === "received_invoice"}
        onClose={() => {
          setState((state) => ({ ...state, dialog: undefined }))
          changeTab("status")
        }}
      />
    </TabPage>
  )
}
