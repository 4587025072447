import * as React from "react"

import { Checkbox, Dialog, Typography } from "@material-ui/core"
import { DoneIllustration } from "../common/assets"
import { PrimaryButton } from "../common/custom-button"
import { SlideUp } from "../common/slideup"
import { colors } from "../common/style"

export const ReceivedSomethingDialog: React.FC<{
  open: boolean
  onClose: () => void
  title: JSX.Element | string
  subtitle: string
  terms?: string
}> = (props) => {
  const [state, setState] = React.useState({
    termsChecked: false,
  })
  React.useEffect(() => {
    if (props.open) {
      setState((state) => ({ ...state, termsChecked: false }))
    }
  }, [props.open])
  return (
    <Dialog open={props.open} TransitionComponent={SlideUp}>
      <div
        style={{
          display: "grid",
          rowGap: "18px",
          textAlign: "center",
          justifyContent: "center",
          placeItems: "center",
          padding: "18px",
        }}
      >
        <img alt="done" src={DoneIllustration} style={{ height: "120px" }} />
        <Typography variant="h6" style={{ color: colors.blue1 }}>
          {props.title}
        </Typography>
        <Typography variant="caption" style={{ color: colors.grey2 }}>
          {props.subtitle}
        </Typography>
        {props.terms && (
          <div
            style={{
              display: "grid",
              gridAutoFlow: "column",
              gap: "8px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Checkbox
              color="primary"
              checked={state.termsChecked}
              onClick={() =>
                setState((state) => ({
                  ...state,
                  termsChecked: !state.termsChecked,
                }))
              }
            />
            <Typography variant="body2" style={{ textAlign: "start" }}>
              {props.terms}
            </Typography>
          </div>
        )}
        <PrimaryButton
          disabled={Boolean(props.terms && !state.termsChecked)}
          variant="primary"
          word="Done"
          onClick={
            props.terms && !state.termsChecked ? () => {} : props.onClose
          }
        />
      </div>
    </Dialog>
  )
}
