import { Link, Typography } from "@material-ui/core"
import React from "react"
import { colors } from "./style"

export const MonospaceLink: React.FC<{
  link: string
  onClick: () => void
  href?: string
  style?: React.CSSProperties
}> = (props) => {
  return (
    <Link
      underline="always"
      href={props.href}
      style={{
        color: colors.blue2,
        cursor: "pointer",
        wordBreak: "break-all",
        ...props.style,
      }}
      onClick={(event: any) => {
        event.stopPropagation()
        props.onClick()
      }}
    >
      <Typography style={{ fontFamily: "monospace", color: colors.blue2 }}>
        {props.link}
      </Typography>
    </Link>
  )
}
