/*
 * The following rules are based on https://www.figma.com/file/wcHKxFJhD5HHY40fNEaQPb/Basic-Rules
 * Recommended VS Code extension: https://marketplace.visualstudio.com/items?itemName=naumovs.color-highlight
 */
/***
 * Legends:
 * 1 = Highest tone
 * 2 = High tone
 * 3 = Mid tone
 * 4 = Low tone
 * 5 = Lowest tone
 */
export const colors = {
    // yellow
    yellow1: "rgb(255, 219, 92)",
    yellow2: "rgb(255, 227, 128)",
    yellow3: "rgb(255, 233, 153)",
    yellow4: "rgb(252, 243, 188)",
    yellow5: "rgb(255, 249, 230)",
    // turquoise
    turquoise1: "rgb(61, 186, 177)",
    turquoise2: "rgb(81, 200, 192)",
    turquoise3: "rgb(121, 211, 206)",
    turquoise4: "rgb(160, 224, 220)",
    turquoise5: "rgb(198, 236, 234)",
    // blue
    blue1: "rgb(47, 78, 107)",
    blue2: "rgb(62, 103, 142)",
    blue3: "rgb(78, 129, 177)",
    blue4: "rgb(113, 154, 193)",
    blue5: "rgb(149, 179, 208)",
    // pastel blue
    pastelBlue1: "rgb(100, 175, 234)",
    pastelBlue2: "rgb(106, 170, 226)",
    pastelBlue3: "rgb(149, 194, 234)",
    pastelBlue4: "rgb(191, 218, 242)",
    pastelBlue5: "rgb(234, 243, 251)",
    // orange
    orange1: "rgb(251, 160, 85)",
    orange2: "rgb(251, 171, 106)",
    orange3: "rgb(252, 185, 131)",
    orange4: "rgb(255, 199, 156)",
    orange5: "rgb(255, 213, 180)",
    // red
    red1: "rgb(249, 111, 109)",
    red3: "rgb(255, 156, 158)",
    red5: "rgb(255, 234, 240)",
    // grey
    grey1: "rgb(66, 66, 66)",
    grey2: "rgb(127, 140, 152)",
    grey3: "rgb(187, 194, 201)",
    grey4: "rgb(218, 220, 223)",
    grey5: "rgb(244, 245, 246)",
};
