import * as React from "react"
import { MonospaceLink } from "../common/monospace-link"
import { InvoiceRequest } from "./invoice-request-dialog"
import { CommissionTypeBadge } from "./invoice-request-group"

export const tablifyInvoiceRequests = (options: {
  includeProject?: boolean
  includeAgency?: boolean
}) => {
  return {
    columns: [
      ...(options.includeProject ? ["Project"] : []),
      "Unit Number",
      "Commission Type",
      "Agent(s)",
      ...(options.includeAgency ? ["Agency Name"] : []),
      "Invoice Request ID",
    ],
    toRow: ({
      invoiceRequest,
      showInvoiceRequest,
    }: {
      invoiceRequest: InvoiceRequest
      showInvoiceRequest: (invoiceRequestId: string) => void
    }): JSX.Element[] => {
      return [
        ...(options.includeProject
          ? [
              <React.Fragment key={`${invoiceRequest.id}-1`}>
                {invoiceRequest.transaction?.project?.parent?.name}
                {invoiceRequest.transaction?.project?.name ===
                invoiceRequest.transaction?.project?.parent?.name
                  ? ""
                  : `(${invoiceRequest.transaction?.project?.name})`}
              </React.Fragment>,
            ]
          : []),
        <React.Fragment key={`${invoiceRequest.id}-2`}>
          {invoiceRequest.transaction?.project?.unit?.unitNum}
        </React.Fragment>,

        <div key={`${invoiceRequest.id}-3`} style={{ display: "grid" }}>
          <CommissionTypeBadge type={invoiceRequest.commissionType} />
        </div>,

        <React.Fragment key={`${invoiceRequest.id}-4`}>
          {invoiceRequest.forAgents?.map((agent, index) => (
            <div key={index}>{agent?.fullName}</div>
          ))}
        </React.Fragment>,

        ...(options.includeAgency
          ? [
              <React.Fragment key={`${invoiceRequest.id}-5`}>
                {invoiceRequest.agency?.name}
              </React.Fragment>,
            ]
          : []),

        <React.Fragment key={`${invoiceRequest.id}-6`}>
          <MonospaceLink
            onClick={() => showInvoiceRequest(invoiceRequest.id)}
            link={invoiceRequest.humanReadableId}
          />
        </React.Fragment>,
      ]
    },
  }
}
