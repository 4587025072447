import * as React from "react"
import { ReceivedSomethingDialog } from "./received-something-dialog"

export const ReceivedAppointmentLetterDialog: React.FC<{
  open: boolean
  onClose: () => void
}> = (props) => {
  return (
    <ReceivedSomethingDialog
      open={props.open}
      onClose={props.onClose}
      title="We’ve Received Your Signed Appointment Letter!"
      subtitle="We’re now able to process your invoices as quickly as possible."
      terms="I will pass the signed copy of the Appointment Letter to my Didian ARM"
    />
  )
}
