var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from "axios";
import { ReactNativeFile, extractFiles } from "extract-files";
import { print } from "graphql";
export { ReactNativeFile };
var getRequestBody = function (_a) {
    var query = _a.query, vars = _a.vars;
    var isNode = typeof process !== "undefined" &&
        process.versions != null &&
        process.versions.node != null;
    var form = !isNode
        ? new FormData()
        : new (require("form-data"))();
    var _b = extractFiles(vars), variables = _b.clone, files = _b.files;
    files === null || files === void 0 ? void 0 : files.forEach(function (keys, file) {
        return keys.forEach(function (key) {
            form.append(key, file);
        });
    });
    form.append("query", print(query));
    if (variables) {
        form.append("variables", JSON.stringify(variables));
    }
    return form;
};
var processResponse = function (_a) {
    var response = _a.response, handleError = _a.handleError;
    var data = response.data, errors = response.errors;
    if (errors && errors.length > 0) {
        var errorObj = new Error(errors.join("\n\n"));
        handleError === null || handleError === void 0 ? void 0 : handleError(errorObj);
        throw errorObj;
    }
    else {
        return data;
    }
};
var processError = function (_a) {
    var error = _a.error, handleError = _a.handleError;
    var errorObj = new Error(error);
    handleError === null || handleError === void 0 ? void 0 : handleError(errorObj);
    throw errorObj;
};
export var graphqlClient = function (_a) {
    var url = _a.url, getToken = _a.getToken, handleError = _a.handleError, getSdk = _a.getSdk;
    return function (options) {
        return getSdk(function (query, vars) { return __awaiter(void 0, void 0, void 0, function () {
            var token, form, error_1;
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, getToken()];
                    case 1:
                        token = _c.sent();
                        form = getRequestBody({
                            query: query,
                            vars: vars,
                        });
                        return [2 /*return*/, axios({
                                url: url,
                                method: "POST",
                                headers: __assign({ Authorization: token }, ((_b = (_a = form.getHeaders) === null || _a === void 0 ? void 0 : _a.call(form)) !== null && _b !== void 0 ? _b : {})),
                                data: form,
                                onUploadProgress: options === null || options === void 0 ? void 0 : options.onUploadProgress,
                            }).then(function (response) {
                                return processResponse({
                                    response: response.data,
                                    handleError: handleError,
                                });
                            })];
                    case 2:
                        error_1 = _c.sent();
                        return [2 /*return*/, processError({
                                error: error_1,
                                handleError: handleError,
                            })];
                    case 3: return [2 /*return*/];
                }
            });
        }); });
    };
};
