import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from "@material-ui/core"
import { Close } from "@material-ui/icons"
import React from "react"
import { InvoiceRequestQueryFragment } from "../../graphql/generated/root.graphql"
import { graphqlSdk } from "../../graphql/sdk"
import { PrimaryButton } from "../common/custom-button"
import { SlideUp } from "../common/slideup"

export type InvoiceRequest = InvoiceRequestQueryFragment
export const InvoiceRequestDialog: React.FC<{
  open: boolean
  token: string
  invoiceRequestId: string | undefined
  onClose: () => void
}> = (props) => {
  const [state, setState] = React.useState({
    loading: false,
    invoiceRequest: undefined as InvoiceRequest | undefined,
  })
  React.useEffect(() => {
    if (props.invoiceRequestId) {
      setState((state) => ({ ...state, loading: true }))
      analytics.track("View invoice request dialog", {
        invoiceRequestId: props.invoiceRequestId,
      })
      graphqlSdk()
        .PaymentPortal_InvoiceRequestDialog({
          token: props.token,
          invoiceRequestId: props.invoiceRequestId,
        })
        .then((result) => {
          setState((state) => ({
            ...state,
            loading: false,
            invoiceRequest: result.paymentPortal.invoiceRequests.findOne,
          }))
        })
        .catch((error) => {
          alert(error)
          setState((state) => ({ ...state, loading: false }))
        })
    }
  }, [props.invoiceRequestId, props.token])

  if (state.invoiceRequest?.emailContent?.htmlBody)
    return (
      <Dialog open={props.open} TransitionComponent={SlideUp} fullScreen>
        <Dialog open={state.loading}>
          <DialogContent style={{ padding: "24px" }}>
            <CircularProgress />
          </DialogContent>
        </Dialog>
        <div
          style={{
            display: "grid",
            gridAutoFlow: "column",
            alignItems: "center",
          }}
        >
          <DialogTitle>
            Invoice Request ({state.invoiceRequest?.humanReadableId})
          </DialogTitle>
          <IconButton
            onClick={props.onClose}
            style={{ marginRight: "16px", justifySelf: "end" }}
          >
            <Close />
          </IconButton>
        </div>
        <DialogContent>
          <div
            dangerouslySetInnerHTML={{
              __html: state.invoiceRequest.emailContent.htmlBody,
            }}
          />
        </DialogContent>
      </Dialog>
    )
  return (
    <Dialog open={props.open} TransitionComponent={SlideUp}>
      <DialogTitle>Invoice Request</DialogTitle>
      <DialogContent>
        {(() => {
          const invoiceRequest = state.invoiceRequest
          return (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gridColumnGap: "36px",
                gridRowGap: "12px",
              }}
            >
              <Divider style={{ gridColumn: "1/span 2" }} />
              <Typography>Invoice Request ID</Typography>
              <Typography>{invoiceRequest?.humanReadableId}</Typography>
              <Typography>Project name</Typography>
              <Typography>
                {invoiceRequest?.emailContent?.templateModel.project_name}
              </Typography>
              <Typography>Unit number</Typography>
              <Typography>
                {invoiceRequest?.emailContent?.templateModel.unit_no}
              </Typography>
              <Typography>Buyer name(s)</Typography>
              <Typography>
                {invoiceRequest?.emailContent?.templateModel.buyers}
              </Typography>
              <Typography> Gross price </Typography>
              <Typography>
                {invoiceRequest?.emailContent?.templateModel.gross_price}
              </Typography>
              <Typography> Agency </Typography>
              <Typography>
                {invoiceRequest?.emailContent?.templateModel.agency}
              </Typography>
              <Typography> Agent(s) </Typography>
              <Typography>
                {invoiceRequest?.emailContent?.templateModel.agents}
              </Typography>
              <Divider style={{ gridColumn: "1/span 2" }} />
              <Typography> Nett price </Typography>
              <Typography style={{ fontWeight: "bold" }}>
                RM{invoiceRequest?.emailContent?.templateModel.nett_price}
              </Typography>
              <Typography> Commission </Typography>
              <Typography style={{ fontWeight: "bold" }}>
                {
                  invoiceRequest?.emailContent?.templateModel
                    .nett_commission_percentage
                }
              </Typography>
              <Typography> Type </Typography>
              <Typography style={{ fontWeight: "bold" }}>
                {invoiceRequest?.emailContent?.templateModel.commission_type}
              </Typography>
              <Typography>
                Payable now (
                {
                  invoiceRequest?.emailContent?.templateModel
                    .commmission_portion
                }
                )
              </Typography>
              <Typography style={{ fontWeight: "bold" }}>
                RM
                {invoiceRequest?.payableAmount}
              </Typography>
              <Divider style={{ gridColumn: "1/span 2" }} />
            </div>
          )
        })()}
      </DialogContent>
      <div
        style={{ display: "grid", justifyContent: "center", padding: "18px" }}
      >
        <PrimaryButton
          disabled={false}
          variant="secondary"
          onClick={props.onClose}
          word="CLOSE"
        />
      </div>
    </Dialog>
  )
}
