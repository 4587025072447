import {
  Card,
  CardActionArea,
  Link,
  Tooltip,
  Typography,
  capitalize,
} from "@material-ui/core"
import React from "react"
import {
  AvatarIcon,
  CalendarIcon,
  EmptyTickIcon,
  HomeIcon,
  YellowTickIcon,
} from "../common/assets"
import { Cue } from "../common/cue"
import { colors } from "../common/style"
import { InvoiceRequest } from "./invoice-request-dialog"

export type InvoiceRequestsGroup = {
  parentProject:
    | {
        id: string
        name: string
      }
    | undefined
    | null
  childProject:
    | {
        id: string
        name: string
      }
    | undefined
    | null
  agency:
    | {
        id: string
        name: string
      }
    | undefined
    | null

  unsignedAppointmentLetterId: string | undefined | null

  invoiceRequests: InvoiceRequest[]
}

export const InvoiceRequestGroupView: React.FC<{
  invoiceRequestGroups: InvoiceRequestsGroup[]
  selectedInvoiceRequestIds: string[]
  showCheckbox: boolean
  showCheckboxCue: boolean
  isSmallScreen: boolean
  onSelectInvoiceRequest: (transactionId: string) => void
  focusUnsignedAppointmentLetter: (unsignedAppointmentLetterId: string) => void
  showInvoiceRequest: (invoiceRequestId: string) => void
}> = (props) => {
  if (props.invoiceRequestGroups.length === 0) {
    return <React.Fragment />
  }

  return (
    <div style={{ display: "grid", gap: 18 }}>
      {props.invoiceRequestGroups.map((group, index) => {
        const projectName = `${group.parentProject?.name} ${
          group.childProject &&
          group.childProject.name !== group.parentProject?.name
            ? `(${group.childProject.name})`
            : ""
        }`

        const agencyName = group.agency?.name

        const IconWithWord = ({
          iconSrc,
          word,
        }: {
          iconSrc: string
          word: string
        }) => {
          return (
            <div
              style={{
                display: "grid",
                alignItems: "center",
                gap: 8,
                gridAutoFlow: "column",
                justifyContent: "start",
              }}
            >
              <img alt="home_icon" src={iconSrc} style={{ width: "14px" }} />
              <Tooltip title={word} arrow>
                <Typography
                  style={{
                    fontWeight: 400,
                    color: colors.grey1,
                    fontSize: props.isSmallScreen ? 16 : 18,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {word}
                </Typography>
              </Tooltip>
            </div>
          )
        }
        return (
          <Card key={index}>
            <CardActionArea
              disabled
              style={{ padding: 16, backgroundColor: colors.grey5 }}
            >
              <Typography
                style={{ fontWeight: 600, fontSize: 16, color: colors.grey1 }}
              >
                {projectName}
                <br />
                {"@ "}
                {agencyName}
              </Typography>
            </CardActionArea>
            <div style={{ display: "grid", padding: 16, gap: 16 }}>
              {group.invoiceRequests.map((invoiceRequest) => {
                const selected = props.selectedInvoiceRequestIds.includes(
                  invoiceRequest.id,
                )
                return (
                  <div
                    key={invoiceRequest.id}
                    id={invoiceRequest.id}
                    className={
                      props.showCheckbox && !props.isSmallScreen
                        ? "hoverable"
                        : undefined
                    }
                    style={{
                      borderWidth: 1.4,
                      borderStyle: "solid",
                      borderColor: selected ? colors.blue1 : colors.grey4,
                      backgroundColor: selected ? colors.yellow5 : undefined,
                      transition: "background-color 0.26s",
                      borderRadius: 8,
                      display: "grid",
                      padding: 16,
                      gap: 16,
                      gridTemplateColumns: "1fr auto",
                      gridTemplateRows: "auto auto",
                      cursor: props.showCheckbox ? "pointer" : undefined,
                    }}
                    onClick={() =>
                      props.showCheckbox &&
                      props.onSelectInvoiceRequest(invoiceRequest.id)
                    }
                  >
                    {/* Title */}
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "auto 1fr",
                        gridTemplateRows: "auto 1fr",
                        alignItems: "center",
                        columnGap: 8,
                        gridColumn: 1,
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: 16,
                          color: colors.grey2,
                          gridColumn: `1 / span 2`,
                        }}
                      >
                        Invoice Request ID
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "20px",
                          fontWeight: 600,
                          color: colors.grey1,
                        }}
                      >
                        {invoiceRequest.humanReadableId}
                      </Typography>
                      <Link
                        style={{
                          color: colors.turquoise1,
                          cursor: "pointer",
                          justifySelf: "start",
                        }}
                        onClick={() =>
                          props.showInvoiceRequest(invoiceRequest.id)
                        }
                      >
                        <Typography style={{ fontSize: 16, fontWeight: 600 }}>
                          View
                        </Typography>
                      </Link>
                    </div>

                    {/* Check box */}
                    {props.showCheckbox && (
                      <div style={{ gridColumn: 2, gridRow: 1 }}>
                        <Cue showCue={props.showCheckboxCue}>
                          <img
                            alt="checkbox_icon"
                            style={{ width: "14px" }}
                            src={selected ? YellowTickIcon : EmptyTickIcon}
                          />
                        </Cue>
                      </div>
                    )}

                    {/* Other information */}
                    <div
                      style={{
                        display: "grid",
                        rowGap: 8,
                        columnGap: 16,
                        justifyContent: "start",
                        alignItems: "start",
                        gridColumn: "1 / span 2",
                        gridRow: 2,
                        gridTemplateColumns: `repeat(auto-fill, 240px)`,
                      }}
                    >
                      <IconWithWord
                        iconSrc={HomeIcon}
                        word={
                          "Unit " +
                            invoiceRequest.transaction.project?.unit?.unitNum ??
                          "(Unknown Unit Number)"
                        }
                      />
                      <IconWithWord
                        iconSrc={CalendarIcon}
                        word={capitalize(invoiceRequest.commissionType)}
                      />
                      {invoiceRequest.forAgents.map((agent) => (
                        <IconWithWord
                          key={agent.fullName}
                          iconSrc={AvatarIcon}
                          word={agent.fullName}
                        />
                      ))}
                    </div>
                  </div>
                )
              })}
            </div>
          </Card>
        )
      })}
    </div>
  )
}

export const CommissionTypeBadge: React.FC<{
  type: string
}> = (props) => {
  const style: React.CSSProperties = {
    height: "24px",
    borderRadius: "4px",
    display: "grid",
    alignItems: "center",
    padding: "0 8px",
    justifySelf: "start",
  }
  return (
    <div style={{ backgroundColor: "#FCF3BC", ...style }}>
      <Typography style={{ fontWeight: "bold" }} variant="caption">
        {props.type.toUpperCase()}
      </Typography>
    </div>
  )
}
