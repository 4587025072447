import Tooltip from "@material-ui/core/Tooltip"
import Typography from "@material-ui/core/Typography"
import React from "react"
import { TabProps } from ".."
import {
  GalAgencyGroupQueryFragment,
  PagingOptions,
  PaymentSlipQueryFragment,
} from "../../../graphql/generated/root.graphql"
import { graphqlSdk } from "../../../graphql/sdk"
import { toUpload } from "../../../graphql/to-upload"
import { saveBase64File } from "../../../utils/save-file"
import { DownloadIcon, PdfIcon } from "../../common/assets"
import { LoadingCircle } from "../../common/loading-circle"
import { Paginator } from "../../common/paginator"
import { colors } from "../../common/style"
import { TabPage } from "../common/tab-page"
import { formatAgency } from "../common/util"
import { GalCard } from "./gal-card"

export type PaymentSlip = PaymentSlipQueryFragment
export type GalAgencyGroup = GalAgencyGroupQueryFragment

export const GalAgencyGroupSection: React.FC<{
  tabProps: TabProps
}> = ({ tabProps: { token, setLoading, handleError, isSmallScreen } }) => {
  const [state, setState] = React.useState({
    items: [] as GalAgencyGroup[],
    pagingOptions: {
      currentPage: 0,
      itemsPerPage: 4,
    } as PagingOptions,
    totalCount: 0,
    lastUpdated: Date.now(),
    loading: false,
  })

  React.useEffect(() => {
    setLoading(true)
    analytics.track("Display GAL agency group section", {})
    graphqlSdk()
      .PaymentPortal_GalAgencyGroupSection({
        token,
        pagingOptions: state.pagingOptions,
      })
      .then(({ paymentPortal: { galAgencyGroups: { findMany: result } } }) => {
        setLoading(false)
        setState((state) => ({
          ...state,
          totalCount: result.totalCount,
          items: result.paginate,
        }))
      })
      .catch(handleError)
  }, [token, handleError, setLoading, state.pagingOptions, state.lastUpdated])

  if (state.items.length === 0) {
    return null
  }

  return (
    <TabPage
      title="General Appointment Letter"
      description={
        <Typography>
          In order for us to process your future invoice for all projects,
          please have your agency’s license holder to{" "}
          <span style={{ fontWeight: "bold", color: "black" }}>
            sign the 1 time appointment letter
          </span>
          (s) below.
        </Typography>
      }
    >
      <LoadingCircle open={state.loading} />
      <div
        style={{
          display: "grid",
          gap: "24px",
          gridTemplateColumns: isSmallScreen ? "1fr" : "1fr 1fr",
        }}
      >
        {state.items.map((galAgencyGroup) => {
          const {
            agencyGroup: { parentAgency: { agency: parentAgency } },
            latestGal,
          } = galAgencyGroup
          const agencyName = formatAgency(parentAgency)
          if (!latestGal) {
            return null
          }
          const { status } = latestGal
          const filename = `Didian GAL (${agencyName}).pdf`
          const file = {
            name: filename,
            onClick: () => {
              setState((state) => ({ ...state, loading: true }))
              analytics.track("View GAL", {})
              graphqlSdk()
                .PaymentPortal_GalAgencyGroupSection_GenerateGal({
                  token,
                  galTemplateId: latestGal.template.id,
                  galAgencyGroupId: galAgencyGroup.id,
                })
                .then(
                  ({ paymentPortal: { generateGal: { generatedPdf } } }) => {
                    setState((state) => ({ ...state, loading: false }))
                    saveBase64File({
                      base64String: generatedPdf,
                      mimeType: "application/pdf",
                      filename,
                    })
                  },
                )
                .catch((error) => {
                  alert(error)
                  setState((state) => ({ ...state, loading: false }))
                })
            },
          }
          const onUpload = (file: File) => {
            setLoading(true)

            analytics.track("Upload GAL Signed Copy", {
              galAgencyGroup: galAgencyGroup.id,
            })
            graphqlSdk()
              .PaymentPortal_GalAgencyGroupSection_UploadGalSignedCopy({
                token,
                file: toUpload(file),
                galAgencyGroupId: galAgencyGroup.id,
                galTemplateId: latestGal.template.id,
              })
              .then(() => {
                setLoading(false)
                setState((state) => ({ ...state, lastUpdated: Date.now() }))
              })
              .catch(handleError)
          }

          const customBottom = (file: { name: string; signedURL: string }) => {
            return (
              <div style={{ padding: 16, display: "grid", gap: 8 }}>
                <Typography variant="caption">Uploaded copy:</Typography>
                <div
                  style={{
                    display: "grid",
                    gridAutoFlow: "column",
                    gap: "8px",
                    alignItems: "center",
                    justifyContent: "start",
                    borderRadius: "8px",
                  }}
                >
                  <img alt="PDF" src={PdfIcon} style={{ height: "24px" }} />
                  <Typography
                    style={{
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                  >
                    {file.name}
                  </Typography>
                  <Tooltip title="Download">
                    <img
                      alt="Download"
                      src={DownloadIcon}
                      style={{
                        height: "24px",
                        cursor: "pointer",
                      }}
                      onClick={() => window.open(file.signedURL)}
                    />
                  </Tooltip>
                </div>
              </div>
            )
          }

          switch (status.__typename) {
            case "GalAgencyGroupGalStatusPendingUpload": {
              return (
                <GalCard
                  key={galAgencyGroup.id}
                  backgroundColor={colors.pastelBlue1}
                  title={"General Appointment Letter"}
                  subtitle={agencyName}
                  bottom={{ type: "normal", file, onUpload }}
                />
              )
            }
            case "GalAgencyGroupGalStatusRejected": {
              return (
                <GalCard
                  key={galAgencyGroup.id}
                  backgroundColor={colors.orange1}
                  title="General Appointment Letter - Rejected (Re-upload Required)"
                  subtitle={agencyName}
                  bottom={{
                    type: "normal",
                    file,
                    onUpload,
                    note: {
                      title: "Reason:",
                      description: status.reason,
                      backgroundColor: colors.orange5,
                    },
                  }}
                />
              )
            }
            case "GalAgencyGroupGalStatusPendingReupload": {
              return (
                <GalCard
                  key={galAgencyGroup.id}
                  backgroundColor={colors.orange1}
                  title="General Appointment Letter - Version Updated (Re-upload Required)"
                  subtitle={agencyName}
                  bottom={{ type: "normal", file, onUpload }}
                />
              )
            }

            case "GalAgencyGroupGalStatusUploaded": {
              return (
                <GalCard
                  key={galAgencyGroup.id}
                  color="black"
                  backgroundColor={colors.yellow1}
                  title="General Appointment Letter - Pending Approval"
                  subtitle={agencyName}
                  bottom={{
                    type: "custom",
                    element: customBottom(status.signedCopy.file),
                  }}
                />
              )
            }
            case "GalAgencyGroupGalStatusApproved": {
              return (
                <GalCard
                  key={galAgencyGroup.id}
                  backgroundColor={colors.turquoise2}
                  title="General Appointment Letter - Approved"
                  subtitle={agencyName}
                  bottom={{
                    type: "custom",
                    element: customBottom(status.signedCopy.file),
                  }}
                />
              )
            }
            case "GalAgencyGroupGalStatusVoided": {
              return (
                <GalCard
                  key={galAgencyGroup.id}
                  backgroundColor={colors.grey1}
                  title="General Appointment Letter - Voided"
                  subtitle={agencyName}
                  bottom={{
                    type: "normal",
                    file,
                    onUpload,
                    note: {
                      backgroundColor: colors.grey5,
                      title: "",
                      description: status.reason,
                    },
                  }}
                />
              )
            }
          }
          return null
        })}
        <Paginator
          pagingOptions={state.pagingOptions}
          totalCount={state.totalCount}
          onChange={(currentPage) =>
            setState((state) => ({
              ...state,
              pagingOptions: {
                ...state.pagingOptions,
                currentPage,
              },
            }))
          }
        />
      </div>
    </TabPage>
  )
}
