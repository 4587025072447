import * as React from "react"
import { ReceivedSomethingDialog } from "./received-something-dialog"

export const ReceivedInvoiceDialog: React.FC<{
  open: boolean
  onClose: () => void
}> = (props) => {
  return (
    <ReceivedSomethingDialog
      open={props.open}
      onClose={props.onClose}
      title="We’ve Received Your Invoice!"
      subtitle="If there are no issues with your invoice, we will process payment shortly."
    />
  )
}
