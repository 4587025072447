import { Link, Typography } from "@material-ui/core"
import * as React from "react"
import { TabProps } from ".."
import {
  InvoiceQueryFragment,
  PagingOptions,
} from "../../../graphql/generated/root.graphql"
import { graphqlSdk } from "../../../graphql/sdk"
import { formatDate, timeAgo } from "../../../utils/date"
import {
  CancelIcon,
  PaidIcon,
  PendingIcon,
  RejectedIcon,
} from "../../common/assets"
import { MonospaceLink } from "../../common/monospace-link"
import { Paginator } from "../../common/paginator"
import { colors } from "../../common/style"
import { Tag } from "../../common/tag"
import { TabPage } from "../common/tab-page"
import { Section } from "../section"
import { tablifyInvoiceRequests } from "../tablify-invoice-requests"

export type Invoice = InvoiceQueryFragment
export const InvoiceTab: React.FC<{ tabProps: TabProps }> = ({
  tabProps: { token, changeTab, setLoading, showInvoiceRequest, handleError },
}) => {
  const [state, setState] = React.useState({
    invoices: [] as Invoice[],
    pagingOptions: { currentPage: 0, itemsPerPage: 5 } as PagingOptions,
    totalCount: 0,
  })

  const invoiceRequestsTable = tablifyInvoiceRequests({
    includeAgency: true,
    includeProject: true,
  })

  const fetchData = React.useCallback(
    (prepromise: Promise<void>) => {
      setLoading(true)
      return prepromise
        .then(() => {
          analytics.track("Display invoices", {})
          return graphqlSdk()
            .PaymentPortal_InvoiceTab({
              token,
              pagingOptions: state.pagingOptions,
            })
            .then(({ paymentPortal: { invoices: { findMany: result } } }) => {
              setLoading(false)
              setState((state) => ({
                ...state,
                invoices: result.paginate,
                totalCount: result.totalCount,
              }))
            })
        })
        .catch(handleError)
    },
    [handleError, setLoading, token, state.pagingOptions],
  )

  React.useEffect(() => {
    fetchData(Promise.resolve())
  }, [fetchData])

  return (
    <TabPage
      title="Invoice Status"
      description="If there are no issues with your invoice, we will process payment shortly."
    >
      <div style={{ display: "grid", gridGap: "24px" }}>
        {state.invoices
          .sort((a, b) => b.createdOn.localeCompare(a.createdOn))
          .map((invoice, index) => (
            <Section
              dontExpandByDefault
              key={index}
              title={
                <div
                  style={{
                    display: "grid",
                    gridAutoFlow: "column",
                    gap: "8px",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                  <Typography style={{ fontWeight: "bold" }}>
                    {`Invoice uploaded on ${formatDate(
                      new Date(invoice.createdOn),
                    )} (${timeAgo.format(new Date(invoice.createdOn))})`}
                  </Typography>
                  {invoice.type === "cancelled" && (
                    <Tag
                      backgroundColor={colors.orange1}
                      label="REMOVED"
                      icon={CancelIcon}
                    />
                  )}
                  {invoice.type === "invalidated" && (
                    <Tag
                      backgroundColor={colors.red1}
                      label="REJECTED"
                      icon={RejectedIcon}
                    />
                  )}
                  {invoice.type === "pending_verification" && (
                    <Tag
                      backgroundColor={colors.blue2}
                      label="PENDING"
                      icon={PendingIcon}
                    />
                  )}
                  {invoice.type === "verified" && invoice.paymentSlip && (
                    <Tag
                      backgroundColor={colors.turquoise2}
                      label="PAID"
                      icon={PaidIcon}
                    />
                  )}
                </div>
              }
              subtitle={
                <div
                  style={{
                    display: "grid",
                    gridAutoFlow: "column",
                    gap: "8px",
                    alignItems: "center",
                    justifyContent: "start",
                  }}
                >
                  {invoice.file && invoice.filename && (
                    <MonospaceLink
                      link={invoice.filename}
                      onClick={() => {}}
                      href={invoice.file.signedURL}
                    />
                  )}
                  {(invoice.type === "pending_verification" ||
                    (invoice.type === "verified" && !invoice.paymentSlip)) && (
                    <Link
                      style={{ cursor: "pointer", color: colors.blue2 }}
                      underline="always"
                      onClick={(
                        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
                      ) => {
                        event.stopPropagation()
                        if (
                          window.confirm(
                            `Remove invoice "${invoice.filename}"?`,
                          )
                        ) {
                          analytics.track("Cancel invoice", {
                            invoiceId: invoice.id,
                          })
                          fetchData(
                            graphqlSdk()
                              .PaymentPortal_InvoiceTab_MainCancelInvoice({
                                token,
                                transactionCommissionInvoiceId: invoice.id,
                              })
                              .then(() => {
                                changeTab("upload_invoice")
                              }),
                          )
                        }
                      }}
                    >
                      <Typography variant="caption">Remove</Typography>
                    </Link>
                  )}
                </div>
              }
              showItemCount={false}
              aboveTable={
                invoice.invalidatedReason ? (
                  <div
                    style={{
                      backgroundColor: colors.red5,
                      padding: "8px 16px",
                      margin: "0 8px 8px 8px",
                      borderRadius: "8px",
                    }}
                  >
                    <Typography variant="caption">
                      This invoice was rejected due to the following reason(s):
                    </Typography>
                    {invoice.invalidatedReason
                      .split("\n")
                      .map((reason, index) => (
                        <Typography key={index}>{reason}</Typography>
                      ))}
                  </div>
                ) : undefined
              }
              table={{
                columns: [...invoiceRequestsTable.columns],
                rows: invoice.invoiceRequests.map((invoiceRequest) => ({
                  values: invoiceRequestsTable.toRow({
                    invoiceRequest,
                    showInvoiceRequest,
                  }),
                })),
              }}
            />
          ))}
        <div />
        <Paginator
          pagingOptions={state.pagingOptions}
          totalCount={state.totalCount}
          onChange={(currentPage) =>
            setState((state) => ({
              ...state,
              pagingOptions: { ...state.pagingOptions, currentPage },
            }))
          }
        />
      </div>
    </TabPage>
  )
}
