export const PdfIcon = "pdf_ico.svg"
export const DownloadIcon = "download_ico.svg"
export const BinIcon = "bin_ico.svg"
export const NumberWrap = "number_wrap.svg"
export const DoneIllustration = "done.svg"
export const Checkmark = "checkmark.svg"

export const PendingIcon = "pending_ico.svg"
export const CancelIcon = "cancel_ico.svg"
export const RejectedIcon = "rejected_ico.svg"
export const PaidIcon = "paid_ico.svg"
export const MoneyBagIcon = "money_bag.svg"
export const CalendarIcon = "calendar.svg"
export const HomeIcon = "home.svg"
export const AvatarIcon = "avatar.svg"
export const YellowTickIcon = "yellow_tick.svg"
export const EmptyTickIcon = "empty_tick.svg"
