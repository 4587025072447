import * as Supabase from "@supabase/supabase-js"

export const supabase = Supabase.createClient(
  "https://nxjhsovptavgjaocmzej.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im54amhzb3ZwdGF2Z2phb2NtemVqIiwicm9sZSI6ImFub24iLCJpYXQiOjE2Nzc2NDMxMjAsImV4cCI6MTk5MzIxOTEyMH0.iDjq2FcUiAZJ7R9jRafaAWx6ykW2qfnaCI0z4kv7XCs",
)

export class Authentication {
  _authenticatingMobileNumber?: string

  listen({
    onAuthStateChanged,
  }: {
    onAuthStateChanged: (authenticated: boolean) => void
  }) {
    supabase.auth.onAuthStateChange((event, session) => {
      onAuthStateChanged(!!session?.user)
    })
  }

  signOut = async () => {
    await supabase.auth.signOut()
  }

  sendVerificationCode = (mobileNumber: string) =>
    new Promise<void>((resolve, reject) => {
      supabase.auth
        .signInWithOtp({ phone: mobileNumber })
        .then(() => {
          this._authenticatingMobileNumber = mobileNumber
          resolve()
        })
        .catch((error) => reject(error))
    })
  submitVerificationCode = (pinCode: string) =>
    new Promise<void>((resolve, reject) => {
      if (!this._authenticatingMobileNumber) {
        reject(new Error("Submit verification code first"))
        return
      }
      supabase.auth
        .verifyOtp({
          phone: this._authenticatingMobileNumber,
          token: pinCode,
          type: "sms",
        })
        .then((result) => {
          if (result.error) {
            reject(result.error)
          } else {
            resolve()
          }
        })
    })

  getAuthToken = async () => {
    const {
      data: { session },
    } = await supabase.auth.getSession()
    if (!session) {
      throw new Error("Please log in first")
    }
    return session.access_token
  }
}

export const authentication = new Authentication()
