import { Link, Paper, Tooltip, Typography } from "@material-ui/core"
import * as React from "react"
import { TabProps } from ".."
import { AppointmentLetterQueryFragment } from "../../../graphql/generated/root.graphql"
import { graphqlSdk } from "../../../graphql/sdk"
import { toUpload } from "../../../graphql/to-upload"
import { getFiles } from "../../../utils/get-files"
import { DownloadIcon, PdfIcon } from "../../common/assets"
import { Bullet } from "../../common/bullet"
import { SecondaryButton } from "../../common/custom-button"
import { SectionTitle } from "../../common/section-title"
import { colors } from "../../common/style"
import { TabPage } from "../common/tab-page"
import { ReceivedAppointmentLetterDialog } from "../received-appointment-letter-dialog"
import { Section } from "../section"
type AppointmentLetter = AppointmentLetterQueryFragment
export const AppointmentLetterTab: React.FC<{
  tabProps: TabProps
}> = ({
  tabProps: {
    token,
    isSmallScreen,
    setLoading,
    handleError,
    focusUnsignedAppointmentLetter,
  },
}) => {
  const [state, setState] = React.useState({
    appointmentLetters: [] as AppointmentLetter[],
    lastUploadedAppointmentLetterId: undefined as string | undefined,
    focusedAppointmentLetterId: undefined as string | undefined,
    dialog: undefined as "received_appointment_letter" | undefined,
  })

  type State = typeof state
  const fetchData = React.useCallback(
    ({
      prepromise,
      updateState,
    }: {
      prepromise: Promise<void>
      updateState?: (_: State) => State
    }) => {
      setLoading(true)
      return prepromise.then(() => {
        return graphqlSdk()
          .PaymentPortal_AppointmentLetterIndex_MainAppointmentLetters({
            token,
          })
          .then(({ displayPaymentPortalData: response }) => {
            setLoading(false)
            setState((state) =>
              (updateState ?? ((x) => x))({
                ...state,
                appointmentLetters: response?.appointmentLetters ?? [],
              }),
            )
          })
          .catch(handleError)
      })
    },
    [setLoading, handleError, token],
  )

  const unfocusUnsignedAppointmentLetter = () => {
    setState((state) => ({
      ...state,
      focusedAppointmentLetterId: undefined,
    }))
  }

  React.useEffect(() => {
    fetchData({ prepromise: Promise.resolve() })
  }, [fetchData])

  const signedAppointmentLetters =
    state.appointmentLetters.filter((letter) => letter.signedVersion) ?? []

  const unsignedAppointmentLetters =
    state.appointmentLetters.filter((letter) => !letter.signedVersion) ?? []

  return (
    <TabPage title="Appointment Letter" description="">
      {unsignedAppointmentLetters.length > 0 && (
        <div>
          <SectionTitle
            title="Sign & Submit Appointment Letter"
            description={
              "In order for us to process your invoice, please have your agency’s license holder to sign the document below."
            }
            tag={{ color: "#E83434", label: "IMPORTANT" }}
          />
          <div
            style={{
              display: "grid",
              gridTemplateColumns: isSmallScreen ? "auto" : "1fr 1fr",
              gap: "16px",
            }}
          >
            {unsignedAppointmentLetters.map((letter) => {
              return (
                <Paper
                  key={letter.id}
                  id={letter.id}
                  style={{ overflow: "auto" }}
                  onClick={() => {
                    focusUnsignedAppointmentLetter(letter.id)
                  }}
                  onMouseEnter={() =>
                    focusUnsignedAppointmentLetter(letter.id, {
                      dontScrollIntoView: true,
                    })
                  }
                  onMouseLeave={unfocusUnsignedAppointmentLetter}
                  elevation={
                    letter.id === state.focusedAppointmentLetterId
                      ? 9
                      : undefined
                  }
                >
                  <div
                    style={{
                      backgroundColor:
                        letter.rejectedSignedVersions.length > 0
                          ? colors.orange1
                          : colors.turquoise2,
                      padding: "16px 24px",
                    }}
                  >
                    <Typography variant="caption" style={{ color: "white" }}>
                      Appointment Letter
                      {letter.rejectedSignedVersions.length > 0 &&
                        " - Rejected"}
                    </Typography>
                    <Typography style={{ color: "white", fontWeight: 600 }}>
                      {letter.parentProject?.name}
                      {letter.childProject?.name
                        ? ` (${letter.childProject.name}) `
                        : " "}
                    </Typography>
                    <Typography variant="body2" style={{ color: "white" }}>
                      {letter.agency?.name}
                    </Typography>
                  </div>
                  <div
                    style={{
                      padding: "16px",
                      display: "grid",
                      rowGap: "16px",
                      columnGap: "8px",
                      gridTemplateColumns: "auto 1fr",
                    }}
                  >
                    {letter.rejectedSignedVersions.length > 0 && (
                      <div
                        style={{
                          borderRadius: "8px",
                          backgroundColor: colors.orange5,
                          padding: "24px 16px",
                          color: colors.blue1,
                          display: "grid",
                          gridColumn: "1 / span 2",
                        }}
                      >
                        {(() => {
                          const mostRecentRejectedLetter =
                            letter.rejectedSignedVersions.sort((b, a) =>
                              (a.rejectedOn ?? "").localeCompare(
                                b.rejectedOn ?? "",
                              ),
                            )[0]
                          return (
                            <React.Fragment>
                              <Typography
                                variant="caption"
                                style={{ fontWeight: "bold" }}
                              >
                                {mostRecentRejectedLetter?.rejectionTitle}
                              </Typography>
                              <Typography variant="caption">
                                {mostRecentRejectedLetter?.rejectionDescription}
                              </Typography>
                            </React.Fragment>
                          )
                        })()}
                      </div>
                    )}
                    <Bullet number="1" style={{ marginTop: "-4px" }} />
                    <Typography
                      variant="caption"
                      style={{ color: colors.grey2 }}
                    >
                      Please download the letter below and have your agency’s
                      license holder to sign.
                    </Typography>
                    <Paper
                      style={{
                        display: "grid",
                        gridColumn: "1 / span 2",
                        gridTemplateColumns: "auto 1fr auto",
                        padding: "8px",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      <img alt="PDF" src={PdfIcon} style={{ height: "32px" }} />
                      <Typography
                        variant="body2"
                        style={{
                          color: colors.grey1,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {letter.template?.name}
                      </Typography>
                      <Tooltip title="Download">
                        <img
                          alt="Download"
                          src={DownloadIcon}
                          style={{ height: "26px", cursor: "pointer" }}
                          onClick={() =>
                            window.open(letter.template?.signedURL, "_blank")
                          }
                        />
                      </Tooltip>
                    </Paper>

                    <Bullet number="2" style={{ marginTop: "-4px" }} />
                    <Typography
                      variant="caption"
                      style={{ color: colors.grey2 }}
                    >
                      Upload the signed copy. The signature must be from your
                      agency's license holder.
                    </Typography>
                    <div
                      style={{
                        display: "grid",
                        gridAutoFlow: "column",
                        gap: "8px",
                        alignItems: "center",
                        justifyContent: "start",
                        justifySelf: "start",
                        gridColumn: "1 / span 2",
                      }}
                    >
                      <SecondaryButton
                        style={{
                          padding: "8px 64px",
                        }}
                        onClick={() => {
                          getFiles({ accept: "application/pdf" }).then(
                            ([file]) => {
                              fetchData({
                                prepromise: graphqlSdk()
                                  .PaymentPortal_AppointmentLetterIndex_MainUploadSignedAppointmentLetter(
                                    {
                                      token,
                                      appointmentLetterId: letter.id,
                                      files: [file].map(toUpload),
                                    },
                                  )
                                  .then(() => {}),
                                updateState: (state) => ({
                                  ...state,
                                  dialog: "received_appointment_letter",
                                  lastUploadedAppointmentLetterId: letter.id,
                                }),
                              })
                            },
                          )
                        }}
                      >
                        Upload
                      </SecondaryButton>
                      <Typography
                        variant="caption"
                        style={{ color: colors.grey2 }}
                      >
                        *PDF format only
                      </Typography>
                    </div>
                  </div>
                </Paper>
              )
            })}
            <div />
            <div />
          </div>
        </div>
      )}
      {unsignedAppointmentLetters.length === 0 && (
        <div
          style={{
            borderRadius: "8px",
            backgroundColor: colors.turquoise5,
            padding: "16px",
            marginBottom: "16px",
          }}
        >
          <Typography>
            Congratulations! All your appointment letters were signed and
            uploaded.
          </Typography>
          <Typography variant="caption">
            That means no further action is required!
          </Typography>
        </div>
      )}

      {signedAppointmentLetters.length > 0 && (
        <div
          style={{
            display: "grid",
            marginTop:
              unsignedAppointmentLetters.length > 0 ? "64px" : undefined,
            rowGap: "16px",
          }}
        >
          <SectionTitle
            title={
              "Uploaded Appointment Letter" +
              (signedAppointmentLetters.length > 0 ? "s" : "")
            }
            description="Thanks for uploading the appointment letter, we are now able to process any future invoice for said projects."
          />
          {(() => {
            const groupedByProjectAgency = signedAppointmentLetters.reduce(
              (result, letter) => {
                const key = [
                  letter.parentProject?.id ?? "",
                  letter.childProject?.id ?? "",
                  letter.agency?.id ?? "",
                ].join("-")
                return {
                  ...result,
                  [key]: [...(result[key] ?? []), letter],
                }
              },
              {} as Record<string, AppointmentLetter[]>,
            )
            return Object.entries(groupedByProjectAgency)
              .map(([key, appointmentLetters]) => {
                return {
                  key,
                  appointmentLetters,
                  parentProjectName:
                    appointmentLetters[0]?.parentProject?.name ?? "",
                  childProjectName:
                    appointmentLetters[0]?.childProject?.name ?? "",
                  agencyName: appointmentLetters[0]?.agency?.name ?? "",
                }
              })
              .sort((a, b) => {
                const stringify = (x: typeof a): string => {
                  return (
                    x.parentProjectName +
                    "_" +
                    x.childProjectName +
                    "_" +
                    x.agencyName
                  )
                }
                return stringify(a).localeCompare(stringify(b))
              })
              .map(
                ({
                  key,
                  appointmentLetters,
                  parentProjectName,
                  childProjectName,
                  agencyName,
                }) => {
                  return (
                    <Section
                      key={key}
                      title={`${parentProjectName} ${
                        childProjectName ? `(${childProjectName})` : ""
                      } @ ${agencyName}`}
                      subtitle={""}
                      showItemCount
                      table={{
                        columns: [
                          "Appointment Letter",
                          "Commencement Date",
                          "Expiry Date",
                          "Action(s)",
                        ],
                        rows: appointmentLetters.map((letter) => ({
                          values: [
                            <div
                              key={`${letter.id}-1`}
                              id={letter.id}
                              style={{
                                display: "grid",
                                gridAutoFlow: "column",
                                gap: "8px",
                                alignItems: "center",
                                justifyContent: "start",
                                transition: "background-color 1s",
                                backgroundColor:
                                  state.lastUploadedAppointmentLetterId ===
                                  letter.id
                                    ? colors.yellow1
                                    : undefined,
                                borderRadius: "8px",
                              }}
                            >
                              <img
                                alt="PDF"
                                src={PdfIcon}
                                style={{ height: "24px" }}
                              />
                              <Typography>
                                {letter.signedVersion?.name}
                              </Typography>
                              <img
                                alt="Download"
                                src={DownloadIcon}
                                style={{
                                  height: "24px",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  window.open(letter.signedVersion?.signedURL)
                                }
                              />
                            </div>,
                            <div key={`${letter.id}-2`}>
                              {new Date(
                                letter.commencementDate,
                              ).toLocaleDateString()}
                            </div>,
                            <div key={`${letter.id}-3`}>
                              {new Date(letter.expiryDate).toLocaleDateString()}
                            </div>,
                            <div key={`${letter.id}-4`}>
                              <Link
                                underline="always"
                                style={{
                                  color: colors.blue2,
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  if (
                                    window.confirm(
                                      `Remove this signed appointment letter (${letter.signedVersion?.name})?`,
                                    )
                                  )
                                    fetchData({
                                      prepromise: graphqlSdk()
                                        .PaymentPortal_AppointmentLetterIndex_MainCancelSignedAppointmentLetter(
                                          {
                                            token,
                                            appointmentLetterId: letter.id,
                                          },
                                        )
                                        .then(() => {}),
                                      updateState: (state) => ({
                                        ...state,
                                        focusedAppointmentLetterId: letter.id,
                                      }),
                                    }).then(() => {
                                      focusUnsignedAppointmentLetter(letter.id)
                                    })
                                }}
                              >
                                <Typography variant="caption">
                                  Remove
                                </Typography>
                              </Link>
                            </div>,
                          ],
                        })),
                      }}
                    />
                  )
                },
              )
          })()}
          <div />
        </div>
      )}
      <ReceivedAppointmentLetterDialog
        open={state.dialog === "received_appointment_letter"}
        onClose={() => {
          setState((state) => ({ ...state, dialog: undefined }))
          const id = state.lastUploadedAppointmentLetterId

          setTimeout(() => {
            if (id) {
              document
                .getElementById(id)
                ?.scrollIntoView({ behavior: "smooth", block: "center" })
              setTimeout(() => {
                setState((state) => ({
                  ...state,
                  lastUploadedAppointmentLetterId: undefined,
                }))
              }, 5000)
            }
          }, 100)
        }}
      />
    </TabPage>
  )
}
