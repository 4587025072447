import { Badge, Divider, Link, Typography, capitalize } from "@material-ui/core"
import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import { useScreenType } from "components/dist/utils/screen"
import dayjs from "dayjs"
import React from "react"
import { TabProps } from ".."
import {
  PagingOptions,
  PaymentSlipQueryFragment,
  ProjectAgencyGroupQueryFragment,
} from "../../../graphql/generated/root.graphql"
import { graphqlSdk } from "../../../graphql/sdk"
import { formatDate } from "../../../utils/date"
import { CalendarIcon, MoneyBagIcon } from "../../common/assets"
import { PrimaryButton } from "../../common/custom-button"
import { Paginator } from "../../common/paginator"
import { colors } from "../../common/style"
import { Tag } from "../../common/tag"
import { TabPage } from "../common/tab-page"
import { formatAgency, formatChildProject } from "../common/util"
import { TncDialog } from "./tnc-dialog"

export type PaymentSlip = PaymentSlipQueryFragment
export type ProjectAgencyGroup = ProjectAgencyGroupQueryFragment
export type Tnc = ProjectAgencyGroup["commissionTncs"][0]["tnc"]
export type ProjectAgencyGroupTncStatus =
  ProjectAgencyGroup["commissionTncs"][0]["status"]

export const ProjectAgencyGroupSection: React.FC<{
  tabProps: TabProps
}> = ({ tabProps: { token, setLoading, handleError } }) => {
  const screenType = useScreenType()
  const [state, setState] = React.useState({
    items: [] as ProjectAgencyGroup[],
    pagingOptions: {
      currentPage: 0,
      itemsPerPage: 4,
    } as PagingOptions,
    totalCount: 0,
    tncDialog: {
      open: false,
      data: undefined as
        | {
            token: string
            tnc: Tnc
            projectAgencyGroup: ProjectAgencyGroup
            status: ProjectAgencyGroupTncStatus
          }
        | undefined,
    },
    lastUpdated: Date.now(),
  })

  React.useEffect(() => {
    setLoading(true)
    analytics.track("Display project agency group section", {})
    graphqlSdk()
      .PaymentPortal_ProjectAgencyGroupSection({
        token,
        pagingOptions: state.pagingOptions,
      })
      .then(
        ({ paymentPortal: { projectAgencyGroups: { findMany: result } } }) => {
          setLoading(false)
          setState((state) => ({
            ...state,
            totalCount: result.totalCount,
            items: result.paginate,
          }))
        },
      )
      .catch(handleError)
  }, [token, handleError, setLoading, state.pagingOptions, state.lastUpdated])

  const closeTncDialog = () => {
    setState((state) => ({
      ...state,
      tncDialog: { ...state.tncDialog, open: false },
      lastUpdated: Date.now(),
    }))
  }

  const openTncDialog = (data: {
    tnc: Tnc
    projectAgencyGroup: ProjectAgencyGroup
    status: ProjectAgencyGroupTncStatus
  }) => {
    setState((state) => ({
      ...state,
      tncDialog: {
        open: true,
        data: {
          token,
          ...data,
        },
      },
    }))
  }
  return (
    <TabPage
      title="Project Commission T&C"
      description={
        "You can view each project’s commission here. This is only viewable by designated agency PIC’s."
      }
    >
      <TncDialog
        open={state.tncDialog.open}
        data={state.tncDialog.data}
        onClose={closeTncDialog}
      />
      <div style={{ display: "grid", gridGap: "24px" }}>
        {state.items.map((projectAgencyGroup, index) => {
          const {
            agencyKind: { childProject },
            agencyGroup: { parentAgency: { agency: parentAgency } },
          } = projectAgencyGroup
          const projectName = formatChildProject(childProject)
          const agencyName = formatAgency(parentAgency)

          const pendingAgreementCount =
            projectAgencyGroup.commissionTncs.filter(
              (tnc) =>
                tnc.status.__typename ===
                  "ProjectAgencyGroupCommissionTncStatusPendingAgreement" ||
                tnc.status.__typename ===
                  "ProjectAgencyGroupCommissionTncStatusPendingReagreement",
            ).length

          const count = projectAgencyGroup.commissionTncs.length

          const isSmallScreen =
            screenType === "sm-tablet" || screenType === "xs-phone"
          const rowSpan = isSmallScreen ? 4 : 2
          const columnSpan = isSmallScreen ? 3 : 5

          const gridTemplateRows = `repeat(${count * rowSpan}, auto)`
          const gridTemplateColumns = `auto auto repeat(${columnSpan - 2}, 1fr)`
          return (
            <Card key={index}>
              <CardActionArea
                disabled
                style={{ padding: 16, backgroundColor: colors.grey5 }}
              >
                <Typography
                  style={{ fontWeight: 600, fontSize: 16, color: colors.grey1 }}
                >
                  {projectName}
                  <br />
                  {`@ ${agencyName}`}{" "}
                </Typography>
              </CardActionArea>
              <div style={{ padding: 16 }}>
                {pendingAgreementCount > 0 && (
                  <div
                    style={{
                      backgroundColor: "rgba(255, 234, 240, 1)",
                      borderRadius: 8,
                    }}
                  >
                    <Typography
                      style={{
                        color: colors.red3,
                        fontSize: 16,
                        fontWeight: 600,
                        padding: "12px 16px",
                        marginBottom: 16,
                      }}
                    >
                      {pendingAgreementCount} T&C
                      {pendingAgreementCount > 1 ? "s" : ""} pending agreement
                    </Typography>
                  </div>
                )}
                <div
                  style={{
                    display: "grid",
                    gridTemplateRows,
                    gridTemplateColumns,
                    gap: 8,
                  }}
                >
                  {/* Left vertical line */}
                  <div
                    style={{
                      marginTop: 5,
                      display: "grid",
                      gridRow: `1 / span ${count * rowSpan}`,
                      gridColumn: 1,
                      borderWidth: 2,
                      borderColor: colors.turquoise5,
                      backgroundColor: colors.turquoise5,
                      borderStyle: "solid",
                      borderRadius: 8,
                      justifySelf: "center",
                      width: 0,
                    }}
                  />
                  {projectAgencyGroup.commissionTncs
                    .sort((a, b) =>
                      b.tnc.latestVersion.commencementDate.localeCompare(
                        a.tnc.latestVersion.commencementDate,
                      ),
                    )
                    .map(({ tnc, status }, index, tncs) => {
                      const action = (() => {
                        switch (status.__typename) {
                          case "ProjectAgencyGroupCommissionTncStatusPendingAgreement":
                          case "ProjectAgencyGroupCommissionTncStatusPendingReagreement": {
                            return (
                              <Badge
                                badgeContent=" "
                                variant="dot"
                                color="error"
                              >
                                <PrimaryButton
                                  variant="primary"
                                  word="View & Agree"
                                  style={{
                                    width: 122,
                                    justifySelf: "end",
                                  }}
                                  onClick={() => {
                                    openTncDialog({
                                      tnc,
                                      projectAgencyGroup,
                                      status,
                                    })
                                  }}
                                />
                              </Badge>
                            )
                          }
                          case "ProjectAgencyGroupCommissionTncStatusAgreed":
                          case "ProjectAgencyGroupCommissionTncStatusTerminated": {
                            return (
                              <Link
                                style={{
                                  color: colors.turquoise2,
                                  cursor: "pointer",
                                }}
                                underline="always"
                                onClick={() =>
                                  openTncDialog({
                                    tnc,
                                    projectAgencyGroup,
                                    status,
                                  })
                                }
                              >
                                <Typography>View T&C</Typography>
                              </Link>
                            )
                          }
                        }
                      })()
                      return (
                        <React.Fragment key={tnc.id}>
                          {/* Blue cirle on vertical line */}
                          <div
                            style={{
                              backgroundColor: colors.turquoise5,
                              borderRadius: 14,
                              height: 14,
                              width: 14,
                              gridColumn: 1,
                              gridRow: index * rowSpan + 1,
                              marginTop: 5,
                            }}
                          />

                          <div
                            style={{
                              gridColumn: 2,
                              gridRow:
                                !isSmallScreen &&
                                status.__typename ===
                                  "ProjectAgencyGroupCommissionTncStatusPendingAgreement"
                                  ? `${index * rowSpan + 1} / span 2`
                                  : index * rowSpan + 1,
                              justifySelf: "start",
                            }}
                          >
                            <Typography
                              style={{
                                color: colors.grey2,
                                height: 24,
                              }}
                            >
                              Effective as of
                            </Typography>
                            <Typography
                              style={{
                                fontWeight: 600,
                                gridColumn: 2,
                                gridRow: index * rowSpan + 2,
                                justifySelf: "start",
                              }}
                            >
                              {dayjs(
                                new Date(tnc.latestVersion.commencementDate),
                              ).format("DD MMM YYYY")}
                              {tnc.latestVersion.endDate
                                ? " to " +
                                  dayjs(
                                    new Date(tnc.latestVersion.endDate.value),
                                  ).format("DD MMM YYYY")
                                : ""}
                            </Typography>
                          </div>

                          <div
                            style={{
                              justifySelf: "end",
                              gridColumn: columnSpan,
                              gridRow: `${index * rowSpan + 1} / span 2`,
                            }}
                          >
                            {action}
                          </div>

                          {/* Commission Tiers */}
                          <div
                            style={{
                              display: "grid",
                              gap: 8,
                              gridTemplateColumns: "auto auto 1fr",
                              alignItems: "center",
                              alignSelf: "start",
                              whiteSpace: "nowrap",
                              gridColumn: isSmallScreen ? `2 / span 2` : 3,
                              gridRow: isSmallScreen
                                ? index * rowSpan + 2
                                : index * rowSpan + 1,
                            }}
                          >
                            {tnc.latestVersion.commissionTiers.map(
                              (tier, index) => (
                                <React.Fragment key={index}>
                                  <img
                                    alt="money_bag"
                                    src={MoneyBagIcon}
                                    style={{ width: "14px" }}
                                  />
                                  <Typography>
                                    {"For "}
                                    {tier.lowerBoundaryInclusive}{" "}
                                    {tier.upperBoundaryInclusive !== undefined
                                      ? `- ${tier.upperBoundaryInclusive} sales`
                                      : `sales & above`}
                                    {": "}
                                  </Typography>
                                  <Typography style={{ fontWeight: 600 }}>
                                    {Number(
                                      (tier.percentage * 100).toPrecision(6),
                                    )}
                                    %
                                  </Typography>
                                </React.Fragment>
                              ),
                            )}
                          </div>

                          {/* Commission Portions */}
                          <div
                            style={{
                              display: "grid",
                              gridAutoFlow: "column",
                              gap: 8,
                              whiteSpace: "nowrap",
                              justifyContent: "start",
                              alignSelf: "start",
                              alignItems: "center",
                              gridColumn: isSmallScreen ? `2 / span 2` : 4,
                              gridRow: isSmallScreen
                                ? status.__typename ===
                                  "ProjectAgencyGroupCommissionTncStatusPendingAgreement"
                                  ? `${index * rowSpan + 3} / span 2`
                                  : index * rowSpan + 3
                                : index * rowSpan + 1,
                            }}
                          >
                            <img
                              alt="calendar"
                              src={CalendarIcon}
                              style={{ width: "14px" }}
                            />
                            <Typography>
                              {tnc.latestVersion.commissionPortions
                                .map((portion) => capitalize(portion.type))
                                .join(", ")}
                            </Typography>
                            <Typography style={{ fontWeight: 600 }}>
                              (
                              {tnc.latestVersion.commissionPortions
                                .map(
                                  (portion) => `${portion.percentage * 100}%`,
                                )
                                .join("/")}
                              )
                            </Typography>
                          </div>

                          {/* Remarks */}
                          <div
                            style={{
                              display: "grid",
                              gridColumn: `2 / span ${columnSpan}`,
                              gridRow: index * rowSpan + rowSpan,
                            }}
                          >
                            <div style={{ marginTop: 8, justifySelf: "start" }}>
                              {(() => {
                                switch (status.__typename) {
                                  case "ProjectAgencyGroupCommissionTncStatusPendingAgreement": {
                                    return null
                                  }
                                  case "ProjectAgencyGroupCommissionTncStatusAgreed": {
                                    return (
                                      <Tag
                                        backgroundColor={colors.turquoise5}
                                        color="black"
                                        label={`Agreed on ${formatDate(
                                          new Date(status.agreement.createdOn),
                                        )} by ${
                                          status.agreement.createdBy.fullName
                                        }`}
                                      />
                                    )
                                  }
                                  case "ProjectAgencyGroupCommissionTncStatusPendingReagreement": {
                                    return (
                                      <Tag
                                        color="black"
                                        backgroundColor={colors.orange5}
                                        label={`Re-agreement required. This T&C was updated on ${formatDate(
                                          new Date(tnc.latestVersion.createdOn),
                                        )}.`}
                                      />
                                    )
                                  }
                                  case "ProjectAgencyGroupCommissionTncStatusTerminated": {
                                    const effectiveDate = formatDate(
                                      new Date(
                                        status.termination.effectiveDate,
                                      ),
                                    )
                                    return (
                                      <Tag
                                        backgroundColor={colors.blue1}
                                        label={`This agreement will be terminated on ${effectiveDate} because "${status.termination.reason}".`}
                                      />
                                    )
                                  }
                                }
                              })()}
                            </div>

                            {/* Divider */}
                            {index < tncs.length - 1 && (
                              <Divider
                                style={{
                                  backgroundColor: colors.grey4,
                                  height: 1.4,
                                  alignSelf: "center",
                                  marginTop: 16,
                                  marginBottom: 8,
                                }}
                              />
                            )}
                          </div>
                        </React.Fragment>
                      )
                    })}
                </div>
              </div>
            </Card>
          )
        })}
        <Paginator
          pagingOptions={state.pagingOptions}
          totalCount={state.totalCount}
          onChange={(currentPage) =>
            setState((state) => ({
              ...state,
              pagingOptions: {
                ...state.pagingOptions,
                currentPage,
              },
            }))
          }
        />
      </div>
    </TabPage>
  )
}
