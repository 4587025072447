import { AppBar, Badge, Tab, Tabs, Typography } from "@material-ui/core"
import { useScreenType } from "components/dist/utils/screen"
import React from "react"
import { PaymentPortal_MainIndexQuery } from "../../graphql/generated/root.graphql"
import { graphqlSdk } from "../../graphql/sdk"
import { LoadingCircle } from "../common/loading-circle"
import { AgreementTab } from "./agreement/agreement-tab"
import { AppointmentLetterTab } from "./appointment-letter"
import { InvoiceRequestDialog } from "./invoice-request-dialog"
import { InvoiceRequestTab } from "./invoice-request/invoice-request-tab"
import { InvoiceTab } from "./invoice/invoice-tab"
import { PaymentSlipsTab } from "./payment-slip/payment-slip-tab"

export type TabType =
  | "agreements"
  | "appointment_letter"
  | "upload_invoice"
  | "status"
  | "payment_slips"

export type TabProps = {
  token: string
  changeTab: (tab: TabType) => void
  showInvoiceRequest: (invoiceRequestId: string) => void
  /** Setting `loading` to false will also refresh the tabs number indicators. */
  setLoading: (loading: boolean) => void
  handleError: (error: Error) => void
  isSmallScreen: boolean
  focusUnsignedAppointmentLetter: (
    unsignedAppointmentLetterId: string,
    options?: {
      dontScrollIntoView: boolean
    },
  ) => void
}

type User = PaymentPortal_MainIndexQuery["paymentPortal"]["user"]

export const TransactionPage: React.FC<{
  onTokenInvalid: () => void
}> = (props) => {
  const [state, setState] = React.useState({
    loading: true,
    expiryDate: new Date(),
    tab: "agreements" as TabType,
    dialog: undefined as "preupload_invoice" | "received_invoice" | undefined,
    invoiceRequestDialog: {
      open: false,
      invoiceRequestId: undefined as string | undefined,
    },
    invoiceRequestsTabActionNeededCount: 0,
    agreementTabActionNeededCount: 0,
    user: undefined as User | undefined,
    initialized: false,
  })
  const screenType = useScreenType()
  const token = window.location.hash.slice(1)
  const focusUnsignedAppointmentLetter = React.useCallback(
    (
      unsignedAppointmentLetterId: string,
      options?: {
        dontScrollIntoView: boolean
      },
    ) => {
      setState((state) => ({
        ...state,
        tab: "appointment_letter",
        focusedAppointmentLetterId: unsignedAppointmentLetterId,
      }))
      if (!options?.dontScrollIntoView) {
        setTimeout(() => {
          document
            .getElementById(unsignedAppointmentLetterId)
            ?.scrollIntoView({ behavior: "smooth", block: "center" })
        }, 300)
      }
    },
    [setState],
  )

  const isSmallScreen = screenType === "xs-phone" || screenType === "sm-tablet"
  const width = isSmallScreen ? undefined : "min(888px, 100vw)"

  const showInvoiceRequest = React.useCallback(
    (invoiceRequestId: string) =>
      setState((state) => ({
        ...state,
        invoiceRequestDialog: {
          open: true,
          invoiceRequestId,
        },
      })),
    [setState],
  )

  const handleError = React.useCallback(
    (error) => {
      alert(error)
      setState((state) => ({ ...state, loading: false }))
      props.onTokenInvalid()
    },
    [setState, props],
  )

  const refreshTabs = React.useCallback(() => {
    graphqlSdk()
      .PaymentPortal_MainIndex_Tabs({ token })
      .then(
        ({
          paymentPortal: {
            galAgencyGroups,
            projectAgencyGroups,
            invoiceRequests,
          },
        }) => {
          const agreementTabActionNeededCount =
            galAgencyGroups.actionNeededCount +
            projectAgencyGroups.actionNeededCount

          const invoiceRequestsTabActionNeededCount =
            invoiceRequests.actionNeededCount

          setState((state) => ({
            ...state,
            agreementTabActionNeededCount,
            invoiceRequestsTabActionNeededCount,
            initialized: true,
            tab: state.initialized
              ? state.tab
              : agreementTabActionNeededCount >
                invoiceRequestsTabActionNeededCount
              ? "agreements"
              : "upload_invoice",
          }))
        },
      )
      .catch(handleError)
  }, [handleError, token])

  React.useEffect(() => {
    refreshTabs()
  }, [refreshTabs])

  const setLoading = React.useCallback(
    (loading) => {
      setState((state) => ({ ...state, loading }))
      if (!loading) {
        refreshTabs()
      }
    },
    [setState, refreshTabs],
  )

  const changeTab = React.useCallback(
    (tab) => {
      analytics.page(tab)
      setState((state) => ({ ...state, tab }))
    },
    [setState],
  )

  const tabProps: TabProps = {
    focusUnsignedAppointmentLetter,
    isSmallScreen,
    handleError,
    setLoading,
    changeTab,
    showInvoiceRequest,
    token,
  }

  React.useEffect(() => {
    graphqlSdk()
      .PaymentPortal_MainIndex({ token })
      .then(({ paymentPortal: { user } }) => {
        analytics.identify(user.id, {
          name: user.fullName,
          mobileNum: user.mobileNum ?? "",
        })
        setState((state) => ({ ...state, user }))
      })
      .catch(handleError)
  }, [token, setState, handleError])

  return (
    <div
      style={{
        display: "grid",
        height: "100%",
        width: "100%",
        alignContent: "start",
        justifyContent: isSmallScreen ? undefined : "center",
        overflow: "auto",
      }}
    >
      <InvoiceRequestDialog
        open={state.invoiceRequestDialog.open}
        token={token}
        invoiceRequestId={state.invoiceRequestDialog.invoiceRequestId}
        onClose={() =>
          setState((state) => ({
            ...state,
            invoiceRequestDialog: {
              ...state.invoiceRequestDialog,
              open: false,
            },
          }))
        }
      />
      <AppBar color="secondary">
        <div
          style={{
            display: "grid",
            gridAutoFlow: isSmallScreen ? "row" : "column",
            width,
            alignSelf: "center",
          }}
        >
          <div
            style={{
              display: "grid",
              alignItems: "center",
              gridAutoFlow: "column",
              justifyContent: "start",
              justifySelf: isSmallScreen ? "center" : "start",
              marginLeft: isSmallScreen ? 0 : 8,
            }}
          >
            <img
              alt="didian-logo"
              src="didian.svg"
              style={{ width: isSmallScreen ? 80 : 96 }}
            />
            <Typography
              variant={isSmallScreen ? "caption" : undefined}
              style={{
                whiteSpace: "nowrap",
                overflow: "auto",
                marginRight: "8px",
              }}
            >
              PAYMENT PORTAL
              {state.user &&
                ` (${state.user.fullName} / ${state.user.mobileNum ?? "-"})`}
            </Typography>
          </div>
          <div style={{ display: "grid", justifySelf: "end" }}>
            <Tabs
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              value={state.tab}
              scrollButtons="auto"
              onChange={(_, tab) => {
                setState((state) => ({ ...state, tab }))
              }}
            >
              <Tab
                label={
                  <Badge
                    badgeContent={state.agreementTabActionNeededCount}
                    color="error"
                  >
                    <Typography variant="caption">Agreements</Typography>
                  </Badge>
                }
                value="agreements"
              />
              {/* We have to hide the appointment letter tab by default as it
              is deprecated in favor of the new GAL feature. */}
              {new URLSearchParams(window.location.search).has("apltab") && (
                <Tab
                  label={
                    <Badge color="error">
                      <Typography variant="caption">
                        Appointment Letter
                      </Typography>
                    </Badge>
                  }
                  value="appointment_letter"
                />
              )}
              <Tab
                label={
                  <Badge
                    color="error"
                    badgeContent={state.invoiceRequestsTabActionNeededCount}
                  >
                    <Typography variant="caption">Upload Invoice</Typography>
                  </Badge>
                }
                value="upload_invoice"
              />
              <Tab
                label={
                  <Typography variant="caption">Invoice Status</Typography>
                }
                value="status"
              />
              <Tab
                label={<Typography variant="caption">Payment Slips</Typography>}
                value="payment_slips"
              />
            </Tabs>
          </div>
        </div>
      </AppBar>
      <LoadingCircle open={state.loading} />
      <div style={{ display: "grid", width }}>
        <div
          style={{
            display: "grid",
            alignContent: "start",
            padding: isSmallScreen ? 16 : 32,
            marginTop: isSmallScreen ? 64 : 32,
          }}
        >
          {state.tab === "agreements" && <AgreementTab tabProps={tabProps} />}
          {state.tab === "appointment_letter" && (
            <AppointmentLetterTab tabProps={tabProps} />
          )}
          {state.tab === "upload_invoice" && (
            <InvoiceRequestTab tabProps={tabProps} />
          )}
          {state.tab === "status" && <InvoiceTab tabProps={tabProps} />}
          {state.tab === "payment_slips" && (
            <PaymentSlipsTab tabProps={tabProps} />
          )}
        </div>
      </div>
    </div>
  )
}
