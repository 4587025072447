import { Link } from "@material-ui/core"
import React from "react"
import { TabProps } from ".."
import {
  PagingOptions,
  PaymentSlipQueryFragment,
} from "../../../graphql/generated/root.graphql"
import { graphqlSdk } from "../../../graphql/sdk"
import { formatDate } from "../../../utils/date"
import { MonospaceLink } from "../../common/monospace-link"
import { Paginator } from "../../common/paginator"
import { TabPage } from "../common/tab-page"
import { Section } from "../section"
import { tablifyInvoiceRequests } from "../tablify-invoice-requests"

const linkStyle: React.CSSProperties = {
  margin: "0 4px",
  fontWeight: "bold",
  cursor: "pointer",
}
export type PaymentSlip = PaymentSlipQueryFragment

export const PaymentSlipsTab: React.FC<{
  tabProps: TabProps
}> = ({ tabProps: { token, showInvoiceRequest, setLoading, handleError } }) => {
  const [state, setState] = React.useState({
    paymentSlips: [] as PaymentSlip[],
    pagingOptions: { currentPage: 0, itemsPerPage: 5 } as PagingOptions,
    totalCount: 0,
  })
  const allPaymentSlips = (state.paymentSlips ?? [])
    .filter(({ voidedOn }) => !voidedOn)
    .flatMap((paymentSlip) => [
      paymentSlip,
      ...paymentSlip.additionalPaymentSlips.filter(({ voidedOn }) => !voidedOn),
    ])

  React.useEffect(() => {
    setLoading(true)

    analytics.track("Display payment slips", {
      pagingOptions: state.pagingOptions,
    })
    graphqlSdk()
      .PaymentPortal_PaymentSlipTab({
        token,
        pagingOptions: state.pagingOptions,
      })
      .then(({ paymentPortal: { paymentSlips: { findMany: result } } }) => {
        setLoading(false)
        setState((state) => ({
          ...state,
          paymentSlips: result.paginate,
          totalCount: result.totalCount,
        }))
      })
      .catch(handleError)
  }, [token, handleError, setLoading, state.pagingOptions])

  const invoiceRequestsTable = tablifyInvoiceRequests({
    includeAgency: true,
    includeProject: true,
  })
  return (
    <TabPage
      title="Commission Paid"
      description={
        <div style={{ display: "flex", flexWrap: "wrap", lineHeight: 1.2 }}>
          Your can view your commission payslips here. For help, please contact
          us via email at
          <Link
            target="_blank"
            href="mailto:support@mydidian.com"
            style={linkStyle}
          >
            support@mydidian.com
          </Link>
          or
          <Link
            target="_blank"
            href="http://wa.me/+60175134394"
            style={linkStyle}
          >
            WhatsApp
          </Link>{" "}
          for any urgent inquiries.
        </div>
      }
    >
      <div style={{ display: "grid", gridGap: "24px" }}>
        {allPaymentSlips.map((paymentSlip, index) => (
          <Section
            key={index}
            title={`Commission paid on ${formatDate(
              new Date(paymentSlip.createdOn),
            )}`}
            subtitle={
              paymentSlip.file?.signedURL ? (
                <MonospaceLink
                  link={paymentSlip.generatedFilename}
                  onClick={() => {}}
                  style={{ cursor: "pointer", justifySelf: "start" }}
                  href={paymentSlip.file?.signedURL}
                />
              ) : (
                paymentSlip.generatedFilename
              )
            }
            showItemCount={false}
            table={{
              columns: ["Invoice Name", ...invoiceRequestsTable.columns],
              rows: paymentSlip.commissionInvoices.flatMap((invoice) =>
                invoice.invoiceRequests.map((invoiceRequest, index) => {
                  return {
                    values: [
                      <React.Fragment key={invoiceRequest.id}>
                        {index === 0 ? invoice.filename : ""}
                      </React.Fragment>,
                      ...invoiceRequestsTable.toRow({
                        invoiceRequest,
                        showInvoiceRequest,
                      }),
                    ],
                  }
                }),
              ),
            }}
          />
        ))}
        <Paginator
          pagingOptions={state.pagingOptions}
          totalCount={state.totalCount}
          onChange={(currentPage) =>
            setState((state) => ({
              ...state,
              pagingOptions: { ...state.pagingOptions, currentPage },
            }))
          }
        />
      </div>
    </TabPage>
  )
}
