import { MuiThemeProvider } from "@material-ui/core"
import { createTheme } from "@material-ui/core/styles"
import React from "react"
import { graphqlSdk } from "../../graphql/sdk"
import { colors } from "../common/style"
import { LoginPage } from "../login"
import { TransactionPage } from "../main"
import "./App.css"

const theme = createTheme({
  typography: {
    fontFamily: `"Source Sans Pro", sans-serif`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
  },
  palette: {
    primary: {
      light: colors.turquoise2,
      main: colors.turquoise2,
      dark: colors.turquoise2,
      contrastText: "rgb(255, 255, 255)",
    },
    secondary: {
      main: "rgb(255,255,255)",
      contrastText: "rgb(88, 89, 91)",
    },
    background: {
      paper: "rgba(255, 255, 255)",
    },
  },
})

export const App: React.FC = () => {
  const [state, setState] = React.useState({
    tokenValid: false,
  })

  React.useEffect(() => {
    const token = window.location.hash.slice(1)
    if (token.length > 0) {
      graphqlSdk()
        .PaymentPortal_App_Home({ token })
        .then(() => setState((state) => ({ ...state, tokenValid: true })))
        .catch((error) => {
          alert(error.toString())
          setState((state) => ({ ...state, tokenValid: false }))
        })
    }
  }, [setState])

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      <MuiThemeProvider theme={theme}>
        {state.tokenValid ? (
          <TransactionPage
            onTokenInvalid={() => setState({ ...state, tokenValid: false })}
          />
        ) : (
          <LoginPage />
        )}
      </MuiThemeProvider>
    </div>
  )
}
