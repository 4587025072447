import { Button, Typography } from "@material-ui/core"
import React from "react"
import { colors } from "./style"

export const PrimaryButton: React.FC<{
  style?: React.CSSProperties
  disabled?: boolean
  onClick: () => void
  word: string
  variant: "primary" | "secondary"
}> = (props) => {
  return (
    <Button
      variant={props.variant === "primary" ? "contained" : undefined}
      disableRipple={props.disabled}
      disableElevation={props.disabled}
      onClick={props.onClick}
      style={{
        height: "40px",
        borderRadius: "28px",
        backgroundColor: props.disabled
          ? `rgba(0, 0, 0, 0.26)`
          : props.variant === "secondary"
          ? undefined
          : colors.turquoise2,
        width: "214px",
        ...props.style,
      }}
    >
      <Typography
        style={{
          textTransform: "none",
          color: props.variant === "primary" ? "white" : colors.grey1,
          fontSize: 16,
          fontWeight: 600,
        }}
      >
        {props.word}
      </Typography>
    </Button>
  )
}

export const SecondaryButton: React.FC<{
  onClick?: () => void
  style?: React.CSSProperties
}> = (props) => {
  return (
    <Button
      onClick={props.onClick}
      style={{
        ...(props.style ?? {}),
        borderColor: colors.turquoise2,
        borderWidth: "1px",
        borderStyle: "solid",
        height: "40px",
        color: colors.turquoise2,
      }}
    >
      {props.children}
    </Button>
  )
}
