import Paper from "@material-ui/core/Paper"
import Tooltip from "@material-ui/core/Tooltip"
import Typography from "@material-ui/core/Typography"
import React from "react"
import { getFiles } from "../../../utils/get-files"
import { DownloadIcon, PdfIcon } from "../../common/assets"
import { Bullet } from "../../common/bullet"
import { SecondaryButton } from "../../common/custom-button"
import { colors } from "../../common/style"

export const GalCard: React.FC<{
  color?: string
  backgroundColor: string
  pretitle?: string
  title: string
  subtitle?: string
  bottom:
    | {
        type: "normal"
        note?: {
          backgroundColor: string
          title: string
          description: string
        }
        file: {
          name: string
          onClick: () => void
        }
        onUpload: (file: File) => void
      }
    | { type: "custom"; element: JSX.Element }
}> = (props) => {
  const color = props.color ?? "white"
  const { bottom } = props
  return (
    <Paper style={{ overflow: "auto" }}>
      <div
        style={{
          backgroundColor: props.backgroundColor,
          padding: "16px 24px",
          transition: "0.26s background-color",
        }}
      >
        {props.pretitle && (
          <Typography variant="caption" style={{ color }}>
            {props.pretitle}
          </Typography>
        )}
        <Typography style={{ color, fontWeight: 600 }}>
          {props.title}
        </Typography>
        <Typography variant="body2" style={{ color }}>
          {props.subtitle}
        </Typography>
      </div>
      {(() => {
        switch (bottom.type) {
          case "custom": {
            return bottom.element
          }
          case "normal": {
            return (
              <div
                style={{
                  padding: "16px",
                  display: "grid",
                  rowGap: "16px",
                  columnGap: "8px",
                  gridTemplateColumns: "auto 1fr",
                }}
              >
                {bottom.note?.backgroundColor && (
                  <div
                    style={{
                      borderRadius: "8px",
                      backgroundColor: bottom.note.backgroundColor,
                      padding: "24px 16px",
                      color: colors.blue1,
                      display: "grid",
                      gridColumn: "1 / span 2",
                    }}
                  >
                    {(() => {
                      return (
                        <React.Fragment>
                          <Typography style={{ fontWeight: "bold" }}>
                            {bottom.note.title}
                          </Typography>
                          <Typography style={{ color: colors.blue1 }}>
                            {bottom.note.description}
                          </Typography>
                        </React.Fragment>
                      )
                    })()}
                  </div>
                )}
                <Bullet number="1" style={{ marginTop: "-4px" }} />
                <Typography variant="caption" style={{ color: colors.grey2 }}>
                  Please download the letter below and have your agency’s
                  license holder to sign.
                </Typography>
                <Paper
                  style={{
                    display: "grid",
                    gridColumn: "1 / span 2",
                    gridTemplateColumns: "auto 1fr auto",
                    padding: "8px",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <img alt="PDF" src={PdfIcon} style={{ height: "32px" }} />
                  <Typography
                    variant="body2"
                    style={{
                      color: colors.grey1,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {bottom.file.name}
                  </Typography>
                  <Tooltip title="Download">
                    <img
                      alt="Download"
                      src={DownloadIcon}
                      style={{ height: "26px", cursor: "pointer" }}
                      onClick={bottom.file.onClick}
                    />
                  </Tooltip>
                </Paper>

                <Bullet number="2" style={{ marginTop: "-4px" }} />
                <Typography variant="caption" style={{ color: colors.grey2 }}>
                  Upload the signed copy. The signature must be from your
                  agency's license holder.
                </Typography>
                <div
                  style={{
                    display: "grid",
                    gridAutoFlow: "column",
                    gap: "8px",
                    alignItems: "center",
                    justifyContent: "start",
                    justifySelf: "start",
                    gridColumn: "1 / span 2",
                  }}
                >
                  <SecondaryButton
                    style={{
                      padding: "8px 64px",
                    }}
                    onClick={() => {
                      getFiles({ accept: "application/pdf" }).then(([file]) => {
                        bottom.onUpload(file)
                      })
                    }}
                  >
                    Upload
                  </SecondaryButton>
                  <Typography variant="caption" style={{ color: colors.grey2 }}>
                    *PDF format only
                  </Typography>
                </div>
              </div>
            )
          }
        }
      })()}
    </Paper>
  )
}
