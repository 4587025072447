import { ArrowRight } from "@material-ui/icons"
import React from "react"

export const Cue: React.FC<{
  showCue: boolean
}> = (props) => {
  return (
    <div style={{ position: "relative" }}>
      {props.showCue && (
        <div
          className="shake-horizontal"
          style={{ position: "absolute", left: "-28px", top: -2 }}
        >
          <ArrowRight fontSize="small" />
        </div>
      )}
      {props.children}
    </div>
  )
}
