import { saveAs } from "file-saver"

/** Modified from https://stackoverflow.com/a/59578316/6587634 */
export async function saveBase64File({
  base64String,
  mimeType,
  filename: fileName,
}: {
  base64String: string
  mimeType: string
  filename: string
}) {
  const fileUrl = "data:" + mimeType + ";base64," + base64String
  const response = await fetch(fileUrl)
  const blob = await response.blob()
  const url = window.URL.createObjectURL(blob)
  saveAs(url, fileName)
}
