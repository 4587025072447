import Typography from "@material-ui/core/Typography"
import * as React from "react"

export const TabPage: React.FC<{
  title: string
  description: string | JSX.Element
}> = (props) => {
  return (
    <React.Fragment>
      <Typography
        style={{ marginTop: 28, fontSize: 24, fontWeight: 600 }}
        variant="h4"
        gutterBottom
      >
        {props.title}
      </Typography>
      <Typography
        style={{
          fontSize: 16,
          marginBottom: props.description ? 32 : 24,
          color: "rgba(127, 140, 152, 1)",
        }}
      >
        {props.description}
      </Typography>
      {props.children}
    </React.Fragment>
  )
}
