import CircularProgress from "@material-ui/core/CircularProgress"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import React from "react"

export const LoadingCircle: React.FC<{ open: boolean }> = (props) => {
  return (
    <Dialog open={props.open}>
      <DialogContent style={{ padding: "24px" }}>
        <CircularProgress />
      </DialogContent>
    </Dialog>
  )
}
