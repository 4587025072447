import React from "react"
import MaskedInput from "react-text-mask"

import {
  AppBar,
  Button,
  Card,
  CircularProgress,
  FormControl,
  FormHelperText,
  Link,
  OutlinedInput,
  Slide,
  Toolbar,
  Typography,
} from "@material-ui/core"
import { authentication } from "../../api/authentication"
import { graphqlSdk } from "../../graphql/sdk"

type Page =
  | "enter_mobile_num"
  | "sending_otp"
  | "enter_otp"
  | "verifying_otp"
  | "retrieving_link"
  | "link_retrieved"
export const LoginPage: React.FC = () => {
  const [state, setState] = React.useState({
    mobileNum: "",
    otp: "",
    error: undefined as string | undefined,
    link: undefined as string | undefined,
    retrieveLinkError: [] as string[],
    page: "enter_mobile_num" as Page,
  })
  const mobileNum = state.mobileNum.replace(/\D/g, "").trim()

  const changePage = (page: Page) => {
    analytics.page(page)
    setState((state) => ({ ...state, page }))
  }
  const submitMobileNum = () => {
    changePage("sending_otp")
    authentication
      .sendVerificationCode("+" + mobileNum)
      .then(() => {
        changePage("enter_otp")
      })
      .catch((error) => {
        alert(error)
        changePage("enter_mobile_num")
      })
  }

  const retrieveLink = () => {
    changePage("retrieving_link")
    graphqlSdk()
      .PaymentPortal_LoginIndex_DisplayPaymentPortalToken()
      .then(
        ({ displayPaymentPortalToken: linkToken, displayProfile: user }) => {
          if (user) {
            analytics.identify(user.id, {
              name: user?.fullName,
              mobileNum: user?.mobileNum ?? "",
            })
          }
          changePage("link_retrieved")
          setState((state) => ({ ...state, link: linkToken.token }))
        },
      )
      .catch((error) => {
        changePage("link_retrieved")
        setState((state) => ({
          ...state,
          link: undefined,
          retrieveLinkError: error
            .toString()
            .includes("No user found with the given credentials.")
            ? [
                "To upload invoices, your agency's PIC needs to login to retrieve a link, which can then be used to upload invoices.",
              ]
            : [error.toString()],
        }))
      })
  }

  React.useEffect(() => {
    authentication.listen({
      onAuthStateChanged: (authenticated) => {
        if (authenticated) {
          retrieveLink()
        } else {
          changePage("enter_mobile_num")
        }
      },
    })

    // eslint-disable-next-line
  }, [])

  const submitOtp = () => {
    changePage("verifying_otp")
    authentication
      .submitVerificationCode(state.otp)
      .then(retrieveLink)
      .catch(() => {
        changePage("enter_otp")
        setState((state) => ({
          ...state,
          error: "Invalid OTP. Please try again.",
          otp: "",
        }))
      })
  }

  const fullLink =
    window.location.protocol + "//" + window.location.host + "#" + state.link

  return (
    <div
      style={{
        display: "grid",
        justifyItems: "center",
        alignItems: "center",
        paddingTop: "120px",
      }}
    >
      <AppBar color="secondary">
        <Toolbar>
          <Typography variant="h6">Didian Payment Portal</Typography>
        </Toolbar>
      </AppBar>
      <Card
        style={{
          padding: "24px",
          display: "grid",
          margin: "24px",
          alignItems: "center",
          gridGap: "24px",
          width: "242px",
        }}
      >
        <img
          alt="didian-logo"
          src="didian.svg"
          style={{ width: "180px", justifySelf: "center" }}
        />
        <div
          style={{
            display: "grid",
            gridAutoFlow: "column",
            height: "200px",
            width: "242px",
          }}
        >
          <Slide
            direction="left"
            in={state.page === "enter_mobile_num"}
            mountOnEnter
            unmountOnExit
          >
            <div
              style={{
                display: "grid",
                gridGap: "18px",
                justifyContent: "center",
                alignContent: "start",
              }}
            >
              <Typography>Enter your mobile number</Typography>
              <FormControl
                variant="outlined"
                onClick={() => {
                  ;(
                    document.getElementById(
                      "formatted-mobile-input",
                    ) as HTMLInputElement
                  )?.setSelectionRange(3, 3)
                }}
              >
                <OutlinedInput
                  labelWidth={0}
                  autoFocus
                  value={state.mobileNum}
                  onChange={({ target: { value } }) =>
                    setState((state) => ({ ...state, mobileNum: value }))
                  }
                  onKeyDown={(event) => {
                    if (event.keyCode === 13) {
                      submitMobileNum()
                    }
                  }}
                  id="formatted-mobile-input"
                  inputComponent={MalaysianMobileNumTextMask as any}
                />
              </FormControl>
              <Button
                variant="contained"
                color="primary"
                disabled={mobileNum.length < 11}
                onClick={submitMobileNum}
              >
                Next
              </Button>
            </div>
          </Slide>
          <Slide
            direction="right"
            in={
              state.page === "sending_otp" ||
              state.page === "verifying_otp" ||
              state.page === "retrieving_link"
            }
            mountOnEnter
            unmountOnExit
          >
            <div
              style={{
                display: "grid",
                gridGap: "18px",
                justifyItems: "center",
                alignContent: "start",
                textAlign: "center",
              }}
            >
              {state.page === "sending_otp" && (
                <Typography>Sending OTP to your mobile phone ...</Typography>
              )}
              {state.page === "verifying_otp" && (
                <Typography>Verifying OTP ...</Typography>
              )}
              {state.page === "retrieving_link" && (
                <Typography>Retrieving link ...</Typography>
              )}
              <CircularProgress />
            </div>
          </Slide>
          <Slide
            direction="left"
            in={state.page === "enter_otp"}
            mountOnEnter
            unmountOnExit
          >
            <div
              style={{
                display: "grid",
                gridGap: "18px",
                alignContent: "start",
                justifyItems: "center",
              }}
            >
              <div style={{ textAlign: "center" }}>
                <Typography>Enter your OTP</Typography>
                <Typography display="block" variant="caption">
                  We sent an SMS with a code to
                </Typography>
                <Typography variant="caption">{state.mobileNum}</Typography>
              </div>
              <FormControl
                variant="outlined"
                onClick={() => {
                  ;(
                    document.getElementById(
                      "formatted-otp-input",
                    ) as HTMLInputElement
                  )?.setSelectionRange(0, 0)
                }}
              >
                <OutlinedInput
                  labelWidth={0}
                  value={state.otp}
                  autoFocus
                  error={Boolean(state.error)}
                  onChange={({ target: { value } }) =>
                    setState((state) => ({ ...state, otp: value }))
                  }
                  onKeyDown={(event) => {
                    if (event.keyCode === 13) {
                      submitOtp()
                    }
                  }}
                  id="formatted-otp-input"
                />
                {state.error && (
                  <FormHelperText error={true}>{state.error}</FormHelperText>
                )}
              </FormControl>
              <div
                style={{
                  display: "grid",
                  gridAutoFlow: "column",
                  gridGap: "18px",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => changePage("enter_mobile_num")}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={
                    state.otp
                      .split("")
                      .filter((char) => "1234567890".includes(char)).length !==
                    6
                  }
                  onClick={submitOtp}
                >
                  Next
                </Button>
              </div>
            </div>
          </Slide>

          <Slide
            direction="left"
            in={state.page === "link_retrieved"}
            mountOnEnter
            unmountOnExit
          >
            <div
              style={{
                display: "grid",
                gridGap: "8px",
                alignContent: "start",
                justifyItems: "center",
              }}
            >
              {state.link ? (
                <React.Fragment>
                  <Typography>Here's your link!</Typography>
                  <Typography variant="caption" style={{ textAlign: "center" }}>
                    WARNING: This portal contain sensitive info like invoices
                    and payment slips. Please only share this link with relevant
                    parties.
                  </Typography>
                  <Link
                    underline="always"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      window.open(fullLink, "_blank")
                    }}
                  >
                    <Typography
                      variant="caption"
                      style={{ wordBreak: "break-all" }}
                    >
                      {fullLink}
                    </Typography>
                  </Link>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Typography>Unable to retrieve your link.</Typography>
                  {state.retrieveLinkError.map((error, index) => (
                    <Typography
                      key={index}
                      style={{ textAlign: "center", color: "red" }}
                      variant="caption"
                    >
                      {error}
                    </Typography>
                  ))}
                  <Link underline="always" color="secondary">
                    <Typography onClick={() => window.location.reload()}>
                      Click here to try again
                    </Typography>
                  </Link>
                </React.Fragment>
              )}
            </div>
          </Slide>
        </div>
        {state.page === "link_retrieved" && (
          <Button
            onClick={() => {
              if (
                window.confirm(
                  "Sign out from Didian Payment Portal?" +
                    "\nTo prevent unnecessary future logins, you should only logout if this is not your device.",
                )
              ) {
                authentication.signOut()
              }
            }}
          >
            Logout
          </Button>
        )}
      </Card>
    </div>
  )
}

interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void
}

function MalaysianMobileNumTextMask(props: TextMaskCustomProps) {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[
        "+",
        "6",
        "0",
        /\d/,
        /\d/,
        " ",
        "-",
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={"\u2000"}
      showMask
    />
  )
}

// eslint-disable-next-line
function OtpTextMask(props: TextMaskCustomProps) {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={"\u2000"}
      showMask
    />
  )
}
