export const formatAgency = (
  agency:
    | {
        name: string
        registrationNum?: string | null
      }
    | undefined,
) => {
  if (!agency) {
    return ""
  }
  return agency.registrationNum
    ? `${agency.name} - ${agency.registrationNum}`
    : agency.name
}

export const formatChildProject = (
  childProject:
    | {
        name: string
        parent?: {
          name: string
        } | null
      }
    | undefined,
) => {
  if (!childProject) {
    return undefined
  }
  return childProject.name === childProject.parent?.name
    ? childProject.name
    : `${childProject.parent?.name} (${childProject.name})`
}
