import Divider from "@material-ui/core/Divider"
import React from "react"
import { TabProps } from ".."
import { GalAgencyGroupSection } from "./gal-agency-group-section"
import { ProjectAgencyGroupSection } from "./project-agency-group-section"

export const AgreementTab: React.FC<{
  tabProps: TabProps
}> = ({ tabProps }) => {
  return (
    <div>
      <GalAgencyGroupSection tabProps={tabProps} />
      <Divider style={{ marginTop: 32 }} />
      <ProjectAgencyGroupSection tabProps={tabProps} />
    </div>
  )
}
